import { useState } from "react";
import { DataType } from "../CustomTable/types";
import Tabs from "../Tab";
import PageTitle from "../PageTitle";
import { StarIcon } from "@heroicons/react/24/outline";
import { StarIcon as SolidStar } from "@heroicons/react/24/solid";
import { Game } from "../GamesTable/types";

export type Tab = {
  id: string;
  label: string;
  content: string;
};

interface GameDetails {
  selectedGame: DataType;
}

const CustomOverlay = ({ selectedGame }: GameDetails) => {
  console.log(selectedGame)
  const [activeTab, setActiveTab] = useState("Overview");

  const tabs: Tab[] = [
    { id: "Overview", label: "Overview", content: "Active tab 1" },
    // { id: "Details", label: "Details", content: "Active tab 2" },
  ];

  return (
    <div className="flex justify-center items-center p-4 bg-input rounded-xl ">
      <div className="w-full bg-input flex flex-col justify-center items-center ">
        <div className="w-full flex flex-col justify-between items-start gap-x-14 lg:flex-row">
          <div className="border border-gray-500">
            <img
              src={selectedGame?.image}
              alt="game image"
              width={350}
              height={250}
            />
          </div>
          <div className="lg:w-[70%] w-[100%] flex flex-col justify-between items-start gap-y-6 mb-2">
            <PageTitle
              title={`${selectedGame?.title}`}
              className="text-[30px] text-white3"
            />
            <div className="gap-y-5 flex lg:flex-row flex-col justify-between items-start w-full text-white font-teko text-[20px] mt-4">
              <div className="lg:w-[50%] w-[100%] flex flex-col justify-between gap-y-6">
                <div>
                  <h1>Overall Rating</h1>
                  <div className="flex flex-row text-primary items-center">
                    {Array.from({ length: 10 }).map((_, index) =>
                      selectedGame?.rating > index ? (
                        <SolidStar key={index} className="h-4 w-4" />
                      ) : (
                        <StarIcon key={index} className="h-4 w-4" />
                      )
                    )}
                    <h1 className="mt-1 ml-2">{`${Math.ceil(selectedGame?.rating)}/10`}</h1>
                  </div>
                </div>
                <div className="flex flex-row justify-between items-start w-full">
                  <div className="lg:w-[50%] w-[100%]">
                    <h1>Playtime</h1>
                    {selectedGame?.min?.playTime == selectedGame?.max?.playerCount ?
                      <h1 className="font-bold font-poppins text-sm opacity-80">{selectedGame?.min?.playTime}</h1> :
                      <h1 className="font-bold font-poppins text-sm opacity-80">{selectedGame?.min?.playTime} - {selectedGame?.max?.playTime}</h1>
                    }
                  </div>
                  {/* <div className="lg:w-[50%] w-[100%] ">
                    <h1>Best Playstyle</h1>
                    <h1 className="font-bold font-poppins text-sm opacity-80">{"Competitive"}</h1>
                  </div> */}
                </div>
              </div>
              <div className="lg:w-[50%] w-[100%] flex flex-col justify-between gap-y-6">
                <div>
                  <h1>Complexity</h1>
                  <div className="flex flex-row items-center text-primary">
                    {selectedGame?.qualities?.complexity == undefined ?
                      null :
                      Array.from({ length: 10 }).map((_, index) =>
                        selectedGame?.qualities?.complexity > index ? (
                          <SolidStar key={index} className="h-4 w-4" />
                        ) : (
                          <StarIcon key={index} className="h-4 w-4" />
                        )
                      )
                    }
                    {selectedGame?.qualities?.complexity == undefined ?
                      <h1 className="mt-1 ml-2">N/A</h1> :
                      <h1 className="mt-1 ml-2">{`${Math.ceil(selectedGame?.qualities?.complexity)}/10`}</h1>}
                  </div>
                </div>
                <div className="flex flex-row justify-between items-start w-full">
                  <div className="w-[50%]">
                    <h1>Minimum Age</h1>
                    <h1 className="font-bold font-poppins text-sm opacity-80">
                      {selectedGame?.min?.age == undefined ?
                        <h1 className="font-bold font-poppins text-sm opacity-80">N/A</h1> :
                        <h1 className="font-bold font-poppins text-sm opacity-80">{selectedGame?.min?.age} </h1>
                      }</h1>
                  </div>
                  <div className="w-[50%]">
                    <h1>Best Player Count</h1>
                    {selectedGame?.min?.playerCount == selectedGame?.max?.playerCount ?
                      <h1 className="font-bold font-poppins text-sm opacity-80">{selectedGame?.min?.playerCount}</h1> :
                      <h1 className="font-bold font-poppins text-sm opacity-80">{selectedGame?.min?.playerCount} - {selectedGame?.max?.playerCount}</h1>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-start mt-10">
          <div className="flex gap-x-4 text-white font-teko text-[30px] border-b border-white w-full">
            <Tabs activeTab={activeTab} setTab={setActiveTab} tabs={tabs} />
          </div>
          {activeTab === "Overview" && (
            <div className="w-full flex lg:flex-row flex-col mt-10 justify-between">
              <div className="lg:w-[85%] w-[100%]">
                <span className="text-white text-[20px] font-teko font-bold">
                  <span>Description</span>
                </span>
                <p className="opacity-80 text-white3">
                  {selectedGame?.description}
                </p>
              </div>
              <div className="lg:w-[40%] w-[100%] text-white font-teko text-[20px] lg:ml-3 ml-0">
                <div className="flex justify-center items-center mt-3 lg:mt-0">
                  {/* <div className="w-full flex flex-col border-inputBg  bg-gray-800 rounded-xl p-6 ">
                    <span className=" font-bold">Classification</span>
                    <div className="flex flex-row justify-between font-bold font-poppins text-sm mt-1">
                      <span>Strategy/Euro</span>
                      <span>9</span>
                    </div>
                  </div> */}
                </div>
                <div className="flex justify-center items-center mt-3 mb-3">
                  <div className=" flex flex-col w-full border-inputBg  bg-gray-800 rounded-xl p-6 ">
                    <span className="font-bold">Categories</span>
                    {selectedGame?.qualities?.categories?.map((category) => {
                      return (
                        <div className="flex flex-row justify-between font-bold font-poppins text-sm mt-1">
                          <span>{category}</span>
                        </div>
                      );
                    })}
                    {/* <div className="flex flex-row justify-between font-bold font-poppins text-sm mt-1 ">
                      <span>Economic</span>
                      <span> 10</span>
                    </div>
                    <div className="flex flex-row justify-between font-bold font-poppins text-sm mt-1">
                      <span>Industry/Manufacturing</span>
                      <span> 9</span>
                    </div>
                    <div className="flex flex-row justify-between font-bold font-poppins text-sm mt-1">
                      <span>Post-Napoleonic</span>
                      <span> 9</span>
                    </div>
                    <div className="flex flex-row justify-between font-bold font-poppins text-sm mt-1">
                      <span>Transportation</span>
                      <span> 8</span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          )}

          <br /><br />
          <div><p className="flex justify-right opacity-80 text-sm text-white4"> Source: boardgamegeek.com</p></div>
        </div>
      </div>
    </div>
  );
};
export default CustomOverlay;
