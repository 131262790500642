import { useLayoutEffect, useRef, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';

import { DataType } from './types'
import AvatarImg from '../../assets/images/avatar.png'
import { CircularProgress } from '@mui/material'
import { twMerge } from 'tailwind-merge';
import Button from '../Button';
import Model from "reactjs-popup";
import CustomOverlay from '../CustomOverlay';
import CloseIcon from '@mui/icons-material/Close';
import 'reactjs-popup/dist/index.css';

function CustomTable({
  data = [],
  isEdit,
  isLoading,
  selectedRows = [],
  renderDeleteButton = false,
  gameTitleWidth = 'w-48',
  handleSelect = () => { },
  handleDelete = async () => { },
  handleDeleteGames = async () => { },
  remove
}: {
  data: DataType[],
  isEdit: boolean,
  isLoading?: boolean,
  selectedRows?: string[],
  renderDeleteButton?: boolean,
  gameTitleWidth?: string,
  handleSelect?: (selectedRows: string[]) => void,
  handleDelete?: (gameId: string) => Promise<void>
  handleDeleteGames?: (gameIds:[]) => void,
  remove:boolean
}) {
  const checkbox = useRef<HTMLInputElement | null>(null)
  const [checked, setChecked] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)
  const [filterData, setFilterData] = useState<DataType[]>([])
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState<{
    [key: string]: boolean;
  }>({});

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedRows.length > 0 && selectedRows.length < data.length
    setChecked(selectedRows.length === data.length)
    setIndeterminate(isIndeterminate)
    if (checkbox.current) checkbox.current.indeterminate = isIndeterminate
  }, [selectedRows,  data.length])


  const handleSearchChange = (event:any) => {
    setSearchQuery(event.target.value);
    const filData: DataType[] = data.filter((item) => item?.title?.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase()))
    setFilterData(filData);
  };

  const togglePopUpModel = (gameId: string) => {
    setShowModal((prevState) => ({
      ...prevState,
      [gameId]: !prevState[gameId],
    }));
  };

  const headerStyle =
    'text-white1 font-bold font-poppins text-sm pl-0 px-4 py-6 text-left min-w-24'
  const rowStyle =
    'text-white3 whitespace-nowrap font-poppins font-normal text-sm py-4 pr-3'

  return (
    <div className='bg-table p-4 relative rounded-xl scrollbar-custom'>
      {isLoading &&
        <div className="absolute inset-0 bg-gray-50 opacity-50 z-[999] cursor-not-allowed">
          <div className="flex justify-center items-center h-full">
            <CircularProgress />
          </div>
        </div>
      }
      {
        data.length === 0 ? (
          <p className={`${rowStyle} text-center text-base`}>{isLoading ? '' : 'No data found'}</p>
        ) : (
          <div className='flow-root'>
            <div className='-mx-2 -my-2 overflow-x-auto scrollbar-custom sm:-mx-2 lg:-mx-2'>
              <div className='inline-block min-w-full max-h-[500px] align-middle sm:px-6 lg:px-4'>
                <div className='relative'>
                    <div className='flex w-full justify-between mb-6 gap-4'>
                      <input
                        type='text'
                        placeholder='Search...'
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className=' px-3 py-2 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-300 bg-gray-800 text-white placeholder-gray-400'
                      />
                      {isEdit && remove && (selectedRows.length != 0 && 
                      <div className='flex'>
                        <DeleteIcon
                        sx={{ color: 'red' }}
                        onClick={() =>  handleDeleteGames([])}
                        />
                        <p className='font-semibold font-teko text-[20px] text-white3 text-left mb-2'>Delete</p>
                      </div>
                      )}
                    </div>                    
                  <table className='min-w-full table-fixed divide-y divide-input'>
                    <thead className='sticky top-0 bg-table z-30'>
                      <tr>
                        {isEdit && (
                          <th
                            scope='col'
                            className='relative px-7 py-6 sm:w-10 sm:px-6'
                          ></th>
                        )}
                        <th scope='col' className={twMerge(headerStyle, gameTitleWidth)}>
                          Game
                        </th>
                        <th scope='col' className={headerStyle}>
                          Release
                        </th>
                        <th scope='col' className={headerStyle}>
                          Playercount
                        </th>
                        <th scope='col' className={headerStyle}>
                          Playtime
                        </th>
                        <th scope='col' className={headerStyle}>
                          Weight
                        </th>
                        <th scope='col' className={headerStyle}>
                          Rating
                        </th>
                        {
                          renderDeleteButton ? (
                            <th scope='col'></th>
                          ): null 
                        }
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-input'>
                      {(searchQuery.length !=0 ? filterData : data).map((item: DataType) => (
                      <>
                        <Model
                          modal={true}
                          open={showModal[item._id]}
                          closeOnEscape
                          nested
                          lockScroll
                        >
                        <div className="flex justify-center relative ">
                            <div className="w-full overflow-auto table-container max-h-[100vh]">
                                <CustomOverlay selectedGame={item} />
                            </div>
                            <CloseIcon
                              className="w-12 h-12 bg-input text-['#fff'] ml-2 cursor-pointer absolute top-0 right-0"
                              onClick={() => togglePopUpModel(item._id)}
                            />
                        </div>
                        </Model>
                        <tr
                          key={item?._id}
                          className={`group ${
                            selectedRows.includes(item?._id)
                              ? "bg-gray-700"
                              : "hover:bg-sky-700"
                          }`}
                          onClick={()=>{togglePopUpModel(item?._id)}}
                        >
                          {isEdit && (
                            <td className='relative px-7 sm:w-12 sm:px-6'>
                              {selectedRows.includes(item?._id) && (
                                <div className='absolute inset-y-0 left-0 w-0.5 bg-button' />
                              )}
                              <input
                                type='checkbox'
                                id={'checkBox' + item?._id}
                                className='absolute appearance-none left-4 top-1/2 -mt-2 h-4 w-4 rounded bg-none border-2 border-white3 checked:border-none'
                                value={item?._id}
                                checked={selectedRows.includes(item?._id)}
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                                onChange={() =>
                                  handleSelect([...selectedRows, item?._id])
                                }
                              />
                              {selectedRows.includes(item?._id) && (
                                <svg
                                  className='absolute left-4 top-1/2 -mt-2 w-4 h-4 text-button peer-checked:block'
                                  xmlns='http://www.w3.org/2000/svg'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='currentColor'
                                  strokeWidth='4'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  onClick={(event) =>{
                                    event.stopPropagation();
                                    handleSelect(
                                      selectedRows.filter(
                                        (p: string) => p !== item?._id
                                      )
                                    )
                                  }}
                                >
                                  <polyline points='20 6 9 17 4 12'></polyline>
                                </svg>
                              )}
                            </td>
                          )}
                          <td className={twMerge(rowStyle, `flex gap-2 items-center whitespace-normal ${gameTitleWidth}`)}>
                            <img src={item?.image} alt='avatar' className='w-6 h-6 mr-2' />
                            <span style={{ cursor: "pointer" }}>{item?.title}</span>
                          </td>
                          <td className={rowStyle}>{item?.release}</td>
                          <td className={rowStyle}>{item?.min?.playerCount ?? "N/A"}-{item?.max?.playerCount ?? "N/A"}</td>
                          <td className={rowStyle}>{item?.min?.playTime ?? "N/A"}-{item?.max?.playTime ?? "N/A"}</td>
                          <td className={rowStyle}>{item?.qualities?.complexity?.toFixed(1)}</td>
                          <td className={rowStyle}>{item?.rating?.toFixed(1)}</td>
                          {
                            renderDeleteButton ? (
                              <td className="opacity-0 group-hover:opacity-100">
                                <DeleteIcon
                                 sx={{ cursor: "pointer", color: 'gray' }}
                                  onClick={(event) =>{event.stopPropagation() , handleDelete(item?._id as string)}}
                                />
                                </td>
                            ): null
                          }
                        </tr>
                        </>
                      ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default CustomTable
