import { format } from "date-fns";
import { DateProps } from './types'

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

function DateField({
  placeholder,
  value,
  name,
  minDate,
  onChange,
  label = "Select date",
}: DateProps) {

  return (
    <div className='w-full pt-0 pb-6'>
      <p className='font-semibold font-poppins text-[16px] text-white3 text-left mb-2'>
        {label}
      </p>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          slotProps={{
            textField: {
              error: false,
            },
          }}
          // sx={{
          //   width: "100%",
          //   "& .MuiInputLabel-root.Mui-focused": { color: "#979797" },
          //   "& .MuiOutlinedInput-root": {
          //    "&:hover > fieldset": { borderColor: "#5A5F6B" },
          //    height: "50px",
          //    borderRadius: "6px",
          //   },
          // }}
          minDate={minDate}
          value={new Date(value)}
          onChange={onChange}
        />
      </LocalizationProvider>
    </div>
  );
}

export default DateField
