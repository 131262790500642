import React, { ChangeEvent, forwardRef } from 'react';
import {
  Autocomplete,
  FilterOptionsState,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

interface Game {
  id: string;
  name: string;
}

type GameSearchAutocompleteProps = {
  disabled?: boolean | undefined;
  options: string[];
  value?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  id?: string;
  noOptionsText?: string;
  filterSelectedOptions?: boolean | undefined;
  autoComplete?: boolean | undefined;
  includeInputInList?: boolean | undefined;
  filterOptions?:
    | ((options: string[], state: FilterOptionsState<string>) => string[])
    | undefined;
  getOptionLabel?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  setSelectedGame: React.Dispatch<React.SetStateAction<Game | null>>;
  handleGetGame: (newValue: Game) => Promise<void>;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
};

const GameSearchAutocomplete = forwardRef(
  (props: GameSearchAutocompleteProps, ref) => {
    const {
      disabled,
      options,
      value,
      setSelectedGame,
      handleGetGame,
      setSearchTerm,
      autoComplete = true,
      includeInputInList = true,
      filterSelectedOptions = true,
      id = 'search-games',
      noOptionsText = 'No games',
      getOptionLabel = (option: Game) => option.name,
      filterOptions = (x) => x,
    } = props;
    return (
      <Autocomplete
        id={id}
        ref={ref}
        sx={{ width: 300 }}
        disabled={disabled}
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        options={options}
        autoComplete={autoComplete}
        includeInputInList={includeInputInList}
        filterSelectedOptions={filterSelectedOptions}
        value={value}
        noOptionsText={noOptionsText}
        onChange={(
          event: ChangeEvent<HTMLInputElement>,
          newValue: Game | null
        ) => {
          setSelectedGame(newValue);
          if (newValue) {
            handleGetGame(newValue);
          }
        }}
        onInputChange={(event, newInputValue) => {
          if (event.type === 'change') {
            setSearchTerm(newInputValue);
          }
        }}
        renderInput={(params) => (
          <div className="w-full pt-0 pb-6">
            <div className="relative">
              <div className=" relative flex w-full justify-end">
                <TextField
                  {...params}
                  sx={{ input: { color: 'white' } }}
                  type="text"
                  placeholder="Add Game to Collection"
                  className="w-full sm:max-w-[300px] flex-1 bg-input text-white3 p-3 rounded-xl border border-border outline-none"
                  // label="Search..."
                  fullWidth
                />
                {/* <button className=' absolute right-8 top-4'>
                  <img src={SearchImg} alt='search' />
                </button> */}
              </div>
            </div>
          </div>
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid
                  item
                  sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
                >
                  <Typography variant="body2" color="text.secondary">
                    {option.name}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  }
);

export default GameSearchAutocomplete;
