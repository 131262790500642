import { Box, Typography } from '@mui/material';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    selectedPanelIndex: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, selectedPanelIndex, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={selectedPanelIndex !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {selectedPanelIndex === index && (
                <Box sx={{ py: 3 }}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default CustomTabPanel
