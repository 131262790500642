import * as Yup from 'yup'
import { FormikHelpers, useFormik } from 'formik'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { isSameDay, isToday, parseISO, startOfDay } from 'date-fns';

import Button from '../../components/Button'
import Description from '../../components/Description'
import InputField from '../../components/InputField/InputField'
import TextAreaField from '../../components/TextAreaField/TextAreaField'
import DateField from '../../components/DateField'
import { FormValues, GameSetUpProps } from './types'

import useCurrentValue from '../../hooks/useCurrentValue'

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .trim("Title cannot include leading and trailing spaces")
    .strict(true),
  description: Yup.string()
    .required('Description is required')
    .trim("Description cannot include leading and trailing spaces")
    .strict(true),
  scheduledDate: Yup.string().required('Date is required.'),
  time: Yup.string().required('Time is required.'),
  location: Yup.string().
    required('Place is required.')
    .trim("Place cannot include leading and trailing spaces")
    .strict(true),
});

function GameSetUpAboutPage({
  isNewUser,
  setCurrentStep,
}: GameSetUpProps) {
  const { currentGameSetupValue, setCurrentGameSetupValue } = useCurrentValue()

  const handleSubmit = async (values: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
    const scheduledDate = parseISO(values.scheduledDate)
    const currentDateTime = new Date()
    if (scheduledDate < startOfDay(currentDateTime)) {
      setErrors({ "scheduledDate": "Please select future Date" })
      return
    }
    if (isSameDay(scheduledDate, currentDateTime) && parseISO(values.time) <= currentDateTime) {
      setErrors({ "time": "Please select future time" })
      return
    }
    setCurrentGameSetupValue({
      title: values.title,
      description: values.description,
      scheduledDate: values.scheduledDate,
      time: values.time,
      location: values.location,
    })
    setTimeout(() => {
      setCurrentStep(isNewUser ? 'collection' : 'games')
    }, 500);
  };

  const formik = useFormik({
    initialValues: {
      title: currentGameSetupValue?.title || '',
      description: currentGameSetupValue?.description || '',
      scheduledDate: currentGameSetupValue?.scheduledDate || '',
      time: currentGameSetupValue?.time || '',
      location: currentGameSetupValue?.location || '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit,
  })

  const handleDateChange = value => {
    formik.setFieldValue("scheduledDate", value?.toISOString() ?? '')
  }

  return (
    <div className='w-full mx-auto flex flex-col gap-4 items-center'>
      <Description text='Set the details about your game night' />
      <form onSubmit={formik.handleSubmit} className='w-full max-w-[584px]'>
        <div className='relative'>
          <InputField
            label='Title'
            name='title'
            placeholder='Enter the title of your game night'
            value={formik.values.title}
            onValueChange={formik.handleChange}
          />
          {formik.touched.title && formik.errors.title && (
            <div className='absolute bottom-0 text-error text-base'>
              {formik.errors.title}
            </div>
          )}
        </div>
        <div className='relative'>
          <TextAreaField
            label='Description'
            name='description'
            placeholder='Describe your game night'
            value={formik.values.description}
            onValueChange={formik.handleChange}
          />
          {formik.touched.description && formik.errors.description && (
            <div className='absolute bottom-0 text-error text-base'>
              {formik.errors.description}
            </div>
          )}
        </div>
        <div className='flex w-full gap-8 justify-between items-end'>
          <div className='relative'>
            <DateField
              label='Date'
              name='scheduledDate'
              minDate={new Date()}
              value={parseISO(formik.values.scheduledDate)}
              onChange={handleDateChange}
            />
            {formik.touched.scheduledDate && formik.errors.scheduledDate && (
              <div className='absolute bottom-0 text-error text-base'>
                {formik.errors.scheduledDate}
              </div>
            )}
          </div>
          <div className='relative'>
            <div className='w-full pt-0 pb-6'>
              <p className='font-semibold font-poppins text-[16px] text-white3 text-left mb-2'>
                Time
              </p>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  name="time"
                  slotProps={{
                    textField: {
                      error: false,
                    },
                  }}
                  minTime={isToday(new Date(formik.values.scheduledDate)) ? new Date() : new Date(formik.values.scheduledDate)}
                  value={parseISO(formik.values.time)}
                  onChange={value => formik.setFieldValue("time", value?.toISOString() ?? '')}
                />
              </LocalizationProvider>
            </div>
            {formik.touched.time && formik.errors.time && (
              <div className='absolute bottom-0 text-error text-base'>
                {formik.errors.time}
              </div>
            )}
          </div>
        </div>
        <div className='relative'>
          <InputField
            label='Place'
            name='location'
            placeholder='Your game night`s location'
            value={formik.values.location}
            onValueChange={formik.handleChange}
          />
          {formik.touched.location && formik.errors.location && (
            <div className='absolute bottom-0 text-error text-base'>
              {formik.errors.location}
            </div>
          )}
        </div>
        <div className='flex w-full justify-end'>
          <Button
            text='Next'
            type='submit'
          />
        </div>
      </form>
    </div>
  );
}

export default GameSetUpAboutPage;
