import bgaApi from '../../state/api'

const invitationService = bgaApi.injectEndpoints({
    endpoints: build => ({
        acceptInvitation: build.mutation({
            query: ({ invitationId }) => ({
                url: `/invitations/${invitationId}/accept`,
                method: 'PUT',
            }),
        }),
    }),
})

export default invitationService

export const {
    useAcceptInvitationMutation,
} = invitationService

export const {
    endpoints: {
        acceptInvitation,
    },
} = invitationService
