import bgaApi from '../../state/api'

import {
  PostUserRequestPayload,
  PostUserResponseBody,
} from './types'

const authService = bgaApi.injectEndpoints({
  endpoints: build => ({
    postUser: build.mutation<PostUserResponseBody, PostUserRequestPayload>({
      query: () => ({
        url: '/api/private',
        method: 'POST',
      }),
    }),
    postLogin: build.query({
      query: (data) => ({
        url: `/users/userinfo`,
        method: 'POST',
        body: data
      }),
    }),
    getControlRoomInfo: build.query({
      query: () => ({
        url: `/users/control-room`,
        method: 'GET',
      }),
      providesTags: ["ControlRoom"]
    }),
    updateUser: build.mutation({
      query: (data) => ({
        url: `/users`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['GetUserProfile', 'GameEvent']
    }),
    getUserProfile: build.query({
      query: () => ({
        url: `/users/profile`,
        method: 'GET',
      }),
      providesTags: ["GetUserProfile"]
    }),
    deleteUser: build.mutation({
      query: () => ({
        url: `/users`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GetUserProfile']
    }),
    updateUserPreferences: build.mutation({
      query: (payload) => ({
        url: `/users/${payload.userId}/updatePreferences`,
        method: "PATCH",
        body: payload.data,
      }),
      invalidatesTags: ['GetUserProfile']
    }),
  }),
})

export default authService

export const {
  usePostUserMutation,
  useLazyPostLoginQuery,
  useGetControlRoomInfoQuery,
  useUpdateUserMutation,
  useGetUserProfileQuery,
  useDeleteUserMutation,
  useUpdateUserPreferencesMutation
} = authService

export const {
  endpoints: {
    postUser,
    postLogin,
    getControlRoomInfo,
    updateUser,
    getUserProfile,
    deleteUser,
    updateUserPreferences
  },
} = authService
