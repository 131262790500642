import bgaApi from '../../state/api'

const eventService = bgaApi.injectEndpoints({
  endpoints: build => ({
    importCollection: build.query({
      query: (bggProfile) => ({
        url: `/import/bgg/collection?bggProfile=${bggProfile}`,
        method: 'GET',
      }),
    }),
    searchGames: build.query({
      query: (title) => ({
        url: `/import/search?title=${title}`,
        method: 'GET',
      }),
    }),
    importGame: build.mutation({
      query: (refid) => ({
        url: `/import/bgg/game?refid=${refid}`,
        method: 'GET',
      }),
      invalidatesTags: (_, error) => error ? [] : ['GetCollections']
    }),
    createGame: build.mutation({
      query: (data) => ({
        url: '/events',
        method: 'POST',
        body: data
      }),
    }),
    getGameEvent: build.query({
      query: (eventId) => ({
        url: `/events/${eventId}`,
        method: 'GET',
      }),
      providesTags: ["GameEvent"]
    }),
    upVoteGame: build.mutation({
      query: ({ eventId, gameId }) => ({
        url: `/events/${eventId}/upvote`,
        method: 'PUT',
        body: { gameId }
      }),
      invalidatesTags: (_, error) => error ? [] : ['GameEvent'],
    }),
    downVoteGame: build.mutation({
      query: ({ eventId, gameId }) => ({
        url: `/events/${eventId}/downvote`,
        method: 'PUT',
        body: { gameId }
      }),
      invalidatesTags: (_, error) => error ? [] : ['GameEvent'],
    }),
    cancelGameNight: build.mutation({
      query: ({ eventId, cancelNote }) => ({
        url: `/events/${eventId}/cancel`,
        method: 'PUT',
        body: { cancelNote }
      }),
      invalidatesTags: (_, error) => error ? [] : ['GameEvent', 'ControlRoom'],
    }),
    finalizeVoting: build.mutation({
      query: ({ eventId }) => ({
        url: `/events/${eventId}/finalize-voting`,
        method: 'PUT',
      }),
      invalidatesTags: (_, error) => error ? [] : ['GameEvent'],
    }),
    duplicateGameEvents: build.mutation({
      query: ({ eventId }) => ({
        url: `/events/${eventId}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: (_, error) => error ? [] : ['ControlRoom'],
    }),
    deleteGameEvent: build.mutation({
      query: ({ eventId }) => ({
        url: `/events/${eventId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, error) => error ? [] : ['ControlRoom'],
    }),
    archiveGameEvent: build.mutation({
      query: ({ eventId }) => ({
        url: `/events/${eventId}/archive`,
        method: 'PUT',
      }),
      invalidatesTags: (_, error) => error ? [] : ['ControlRoom'],
    }),
    updateGameEvent: build.mutation({
      query: ({ data, eventId }) => ({
        url: `/events/${eventId}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: (_, error) => error ? [] : ['GameEvent'],
    }),
  }),
})

export default eventService

export const {
  useLazyImportCollectionQuery,
  useLazySearchGamesQuery,
  useImportGameMutation,
  useCreateGameMutation,
  useGetGameEventQuery,
  useUpVoteGameMutation,
  useDownVoteGameMutation,
  useCancelGameNightMutation,
  useFinalizeVotingMutation,
  useDuplicateGameEventsMutation,
  useDeleteGameEventMutation,
  useArchiveGameEventMutation,
  useUpdateGameEventMutation,
} = eventService

export const {
  endpoints: {
    importCollection,
    searchGames,
    importGame,
    createGame,
    getGameEvent,
    upVoteGame,
    downVoteGame,
    cancelGameNight,
    finalizeVoting,
    duplicateGameEvents,
    deleteGameEvent,
    archiveGameEvent,
    updateGameEvent,
  },
} = eventService
