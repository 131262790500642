import { Provider as ReduxProvider } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { H } from 'highlight.run';
import { ErrorBoundary } from '@highlight-run/react';
import store from './state'
import { useTheme } from './themes/ThemeProvider'
import { AuthContextProvider } from './contexts/AuthContext'
import AlertProvider from "./contexts/AlertContext"
import Main from './main'

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderWidth: 1,
          borderColor: '#5A5F6B',
          backgroundColor: '#313746',
        },
        input: {
          color: 'white'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'white'
        },
        formControl: {
          color: 'white'
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#00AF92'
        },
      }
    },
  },
  palette: {
    primary: {
      main: "#ECC63F",
    },
  },
})

const Root = () => {
  const { currentTheme } = useTheme()
  console.log('v0.7')
  H.init('2d1rx84g', {
    serviceName: "frontend-app",
    tracingOrigins: true,
    privacySetting: "none",
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
        // insert full or partial urls that you don't want to record here
        // Out of the box, Highlight will not record these URLs (they can be safely removed):
        "https://www.googleapis.com/identitytoolkit",
        "https://securetoken.googleapis.com",
      ],
    },
  });

  return (
    <ReduxProvider store={store}>
      <AlertProvider>
        <AuthContextProvider>
          <div
            style={{ backgroundColor: currentTheme.background }}
            className="flex flex-col bg-darkBlue min-h-screen p-2"
          >
            <ThemeProvider theme={theme}>
              <ErrorBoundary>
                <Main />
              </ErrorBoundary>
            </ThemeProvider>
          </div>
        </AuthContextProvider>
      </AlertProvider>
    </ReduxProvider>
  )
}

export default Root;
