import { useOutletContext } from "react-router-dom";

import CustomTabPanel from "../CustomTabPanel";
import { ActivityType } from "../types";

const data: ActivityType[] = [
  {
    _id: "1",
    date: '01.01.2024',
    description: 'Recently participated at this game night: PARTY (LINK) with X, Y, Z'
  },
  {
    _id: "2",
    date: '01.01.2024',
    description: 'Recently participated at this game night: PARTY (LINK) with X, Y, Z'
  },
  {
    _id: "3",
    date: '01.01.2024',
    description: 'Recently participated at this game night: PARTY (LINK) with X, Y, Z'
  },
  {
    _id: "4",
    date: '01.01.2024',
    description: 'Recently participated at this game night: PARTY (LINK) with X, Y, Z'
  },
  {
    _id: "5",
    date: '01.01.2024',
    description: 'Recently participated at this game night: PARTY (LINK) with X, Y, Z'
  },
  {
    _id: "6",
    date: '01.01.2024',
    description: 'Recently participated at this game night: PARTY (LINK) with X, Y, Z'
  },
  {
    _id: "7",
    date: '01.01.2024',
    description: 'Recently participated at this game night: PARTY (LINK) with X, Y, Z'
  },
  {
    _id: "8",
    date: '01.01.2024',
    description: 'Recently participated at this game night: PARTY (LINK) with X, Y, Z'
  },
  {
    _id: "9",
    date: '01.01.2024',
    description: 'Recently participated at this game night: PARTY (LINK) with X, Y, Z'
  },
  {
    _id: "10",
    date: '01.01.2024',
    description: 'Recently participated at this game night: PARTY (LINK) with X, Y, Z'
  }
]

function ActivitiesPage() {
  const [user, selectedPanelIndex] = useOutletContext();
  const headerStyle =
    'text-white1 font-bold font-poppins text-sm pl-0 px-4 py-6 text-left'

  const rowStyle =
    'text-white3 whitespace-nowrap font-poppins font-normal text-sm py-4 pr-3'

  return (
    <CustomTabPanel selectedPanelIndex={selectedPanelIndex} index={0}>
      <div className='bg-table p-4 relative rounded-xl scrollbar-custom'>
        <div className='flow-root'>
          <div className='-mx-2 -my-2 overflow-x-auto scrollbar-custom sm:-mx-2 lg:-mx-2'>
            <div className='inline-block min-w-full max-h-[500px] align-middle sm:px-6 lg:px-4'>
              <div className='relative'>
                <table className='min-w-full table-fixed divide-y divide-input'>
                  <thead className='sticky top-0 bg-table z-30'>
                    <tr>
                      <th scope='col' className={`${headerStyle}`}>
                        Date
                      </th>
                      <th scope='col' className={headerStyle}>
                        Activity
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-input'>
                    {data.map((item: ActivityType) => (
                      <tr key={item._id}>
                        <td className={rowStyle}>{item.date}</td>
                        <td className={rowStyle}>{item.description}</td>
                      </tr>
                    ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomTabPanel>
  )
}

export default ActivitiesPage
