import { useNavigate, useParams } from 'react-router-dom'
import { format } from 'date-fns'

import Button from '../../components/Button'
import Description from '../../components/Description'
import CustomTable from '../../components/CustomTable'
import EditImg from '../../assets/images/edit.png'
import useCurrentValue from '../../hooks/useCurrentValue'
import { useCreateGameMutation, useUpdateGameEventMutation } from '../../services/event'
import useHandleFormApiErrors from '../../hooks/useHandleFormApiErrors'
import { useAlert } from '../../contexts/AlertContext'
import { GameSetUpProps } from './types'

function GameSetUpReviewPage({ setCurrentStep, editMode }: GameSetUpProps) {
  const { currentGameSetupValue, resetGameSetup } = useCurrentValue()
  const params = useParams()
  const [createGame, { isLoading }] = useCreateGameMutation()
  const [updateGameEvent, { isLoading: IsUpdating }] = useUpdateGameEventMutation()
  const handleFormApiErrors = useHandleFormApiErrors()
  const { setAlert } = useAlert()
  const navigate = useNavigate()

  const handleSubmit = async () => {
    try {
      const gameList = currentGameSetupValue.nightGames.map(game => ({
        gameId: game._id,
        yesVote: [],
        noVote: [],
        ...(editMode ? currentGameSetupValue?.gameList?.find(item => item.gameId._id === game._id) : {})
      }))
      const gameData = {
        ...Object.fromEntries(
          Object.entries(currentGameSetupValue).filter(([key]) => key !== 'collectionGames' && key !== 'nightGames')
        ),
        gameList,
      }
      console.log({ gameData, currentGameSetupValue })
      if (editMode) {
        const response = await updateGameEvent({ data: gameData, eventId: params.eventId })
        console.log({ response })
        if ('error' in response) {
          handleFormApiErrors({
            error: {},
            defaultMessage: response.error.data?.error ?? "Something went wrong, please try again.",
            showFieldErrorsAsToast: true,
          })
        } else {
          setAlert({
            description: "Successfully updated game night!",
            type: "success",
          })
          resetGameSetup()
          await new Promise(resolve => setTimeout(resolve, 500))
          navigate(`/game-nights/${params.eventId}`)
        }
      } else {
        const response = await createGame(gameData)
        console.log({ response })
        if ('error' in response) {
          handleFormApiErrors({
            error: {},
            defaultMessage: response.error.data?.error ?? "Something went wrong, please try again.",
            showFieldErrorsAsToast: true,
          })
        } else {
          setAlert({
            description: "Successfully created game night!",
            type: "success",
          })
          resetGameSetup()
          await new Promise(resolve => setTimeout(resolve, 500))
          navigate(`/game-nights/${response.data.event._id}`)
        }
      }
    } catch (error) {
      handleFormApiErrors({
        error: {},
        defaultMessage: error.data?.error ?? "Something went wrong, please try again.",
        showFieldErrorsAsToast: true,
      })
    }
  }

  return (
    <div className='w-full mx-auto flex flex-col gap-4 items-center'>
      <Description text="Review your game night's information" />
      <div className='flex flex-col w-full items-start'>
        <div className='flex items-center gap-3 w-full'>
          <p className=' text-white2 text-[40px] font-bold font-teko text-left'>
            About
          </p>
          <img
            src={EditImg}
            alt='edit'
            onClick={() => {
              setCurrentStep('about')
            }}
            className=' cursor-pointer'
          />
        </div>
        <p className=' text-white2 font-semibold text-base font-poppins py-2'>
          Title
        </p>
        <p className=' text-white2 font-normal text-base font-poppins '>
          {currentGameSetupValue?.title || ''}
        </p>
        <p className=' text-white2 font-semibold text-base font-poppins py-2 '>
          Description
        </p>
        <p className=' max-w-[584px] text-white2 font-normal text-base font-poppins '>
          {currentGameSetupValue?.description || ''}
        </p>
        <div className='flex w-full'>
          <div className=' w-1/2 max-w-[292px]'>
            <p className=' text-white2 font-semibold text-base font-poppins py-2'>
              Date
            </p>
            <p className=' text-white2 font-normal text-base font-poppins '>
              {currentGameSetupValue?.scheduledDate ? format(new Date(currentGameSetupValue.scheduledDate), "MM/dd/yyyy") : ''}
            </p>
          </div>
          <div>
            <p className=' text-white2 font-semibold text-base font-poppins py-2'>
              Time
            </p>
            <p className=' text-white2 font-normal text-base font-poppins '>
              {currentGameSetupValue?.time ? format(new Date(currentGameSetupValue.time), "hh:mm aa") : ''}
            </p>
          </div>
        </div>
        <p className=' text-white2 font-semibold text-base font-poppins py-2'>
          Place
        </p>
        <p className=' text-white2 font-normal text-base font-poppins '>
          {currentGameSetupValue?.location || ''}
        </p>
      </div>

      <div className='w-full'>
        <div className='flex items-center gap-3 w-full'>
          <p className=' text-white2 text-[40px] font-bold font-teko text-left'>
            Games
          </p>
          <img
            src={EditImg}
            alt='edit'
            onClick={() => {
              setCurrentStep('games')
            }}
            className=' cursor-pointer'
          />
        </div>
        <CustomTable
          data={currentGameSetupValue?.nightGames || []}
          isEdit={false}
        />
      </div>

      <div className='flex flex-col w-full items-start'>
        <div className='flex items-center gap-3 w-full'>
          <p className=' text-white2 text-[40px] font-bold font-teko text-left'>
            Friends
          </p>
          <img
            src={EditImg}
            alt='edit'
            onClick={() => {
              setCurrentStep('friends')
            }}
            className=' cursor-pointer'
          />
        </div>
        <div className='flex gap-4 flex-wrap pb-8'>
          {currentGameSetupValue.guests.map((email, i) => (
            <div className='flex max-w-full bg-tags px-4 py-3 rounded-lg items-center gap-4' key={i}>
              <p className=' inline-block text-white3 font-poppins font-normal text-base text-center truncate '>
                {email}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className='flex w-full justify-between'>
        <Button
          text='Previous'
          type='button'
          btnFor='prev'
          disabled={isLoading || IsUpdating}
          onClick={() => {
            setCurrentStep('friends')
          }}
        />
        <Button
          text={editMode ? 'Update Game Night' : 'Create Game Night'}
          type='button'
          disabled={isLoading || IsUpdating}
          onClick={handleSubmit}
        />
      </div>
    </div>
  )
}

export default GameSetUpReviewPage
