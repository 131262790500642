import bgaApi from '../../state/api'

const fileService = bgaApi.injectEndpoints({
  endpoints: build => ({
    getSignedUploadUrl: build.query({
      query: ({ fileExtension }) => ({
        url: `/files/get-signed-upload-url?fileExtension=${fileExtension}`,
        method: 'POST',
      }),
    }),
    deleteStoredFile: build.query({
      query: ({ fileKey }) => ({
        url: `/files/deleteFile?fileKey=${fileKey}`,
        method: 'POST',
      }),
    }),
  }),
})

export default fileService

export const {
  useLazyGetSignedUploadUrlQuery,
  useLazyDeleteStoredFileQuery,  
} = fileService

export const {
  endpoints: {
    getSignedUploadUrl,
    deleteStoredFile
  },
} = fileService
