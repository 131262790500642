import { Dispatch, SetStateAction } from "react";
import { Tab } from "../CustomOverlay/CustomOverlay";

interface MultiTabs {
  activeTab: string;
  setTab: Dispatch<SetStateAction<string>>;
  tabs: Tab[];
}

const Tabs = ({ activeTab, setTab, tabs }: MultiTabs) => {
  return (
    <div className="">
      <div className="text-white flex flex-row">
        {tabs.map((tab: Tab, index: number) => (
          <tr
            key={tab.id}
            className={ activeTab === tab.id ? 'text-primary underline underline-offset-8':''}
            onClick={() => setTab(tab.id)}
            style={{ marginRight: index !== tabs.length - 1 ? "2rem" : 0 }}
          >
            {tab.label}
          </tr>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
