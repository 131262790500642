import { SxProps, Theme,Checkbox } from "@mui/material";
import Button from "../Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

type AlertDialogProps = {
  open: boolean;
  onAgree: () => void;
  onClose: () => void;
  title?: string | JSX.Element;
  text?: string | JSX.Element;
  titleCancel?: string;
  titleAgree?: string;
  sxBtn1?: SxProps<Theme>;
  sxBtn2?: SxProps<Theme>;
  disableAgree?: boolean;
  handleChange?:() => void;
  isChecked?:boolean
  showCheckBox?:boolean
};

const CustomAlert = ({
  onAgree,
  onClose,
  title,
  text,
  open,
  titleAgree,
  titleCancel,
  sxBtn1,
  sxBtn2,
  disableAgree,
  handleChange,
  isChecked,
  showCheckBox
}: AlertDialogProps) => {
  return (
    <Dialog className=" p-4 rounded-xl" open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        {showCheckBox && (
          <div className="flex w-full mt-5 gap-1">
            <Checkbox checked={isChecked} onChange={handleChange} />
            <DialogContentText sx={{ fontSize: "15px", marginTop: "10px" }}>
              {"Don’t show this message again"}
            </DialogContentText>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          text={titleCancel || ""}
          type="button"
          btnFor="cancel"
          onClick={onClose}
        />
        <Button
          text={titleAgree || ""}
          type="button"
          disabled={false}
          onClick={onAgree}
        />
      </DialogActions>
    </Dialog>
  );
};
export default CustomAlert;
