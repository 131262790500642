import { Ref, forwardRef, useEffect, useState } from 'react'

import SubTitle from '../../components/SubTitle'
import CustomTable from '../../components/CustomTable'
import CustomAlert from '../../components/CustomAlert'
import { DataType, GameType } from '../../components/CustomTable/types';
import useHandleFormApiErrors from '../../hooks/useHandleFormApiErrors';
import { useAlert } from '../../contexts/AlertContext';
import { useDeleteGameMutation, useDeleteGamesMutation} from '../../services/collection';
import GameSearchAutocomplete from '../../components/GameSearchAutocomplete';
import { useGetUserProfileQuery,useUpdateUserPreferencesMutation } from '../../services/user';

interface Game {
  id: string;
  name: string;
}

const CollectionsTableWithSearch = forwardRef(({
  subTitle,
  data,
  isEdit,
  isFetching,
  options,
  selectedGame,
  renderDeleteButton,
  gameTitleWidth,
  setSelectedGame,
  handleGetGame,
  setSearchTerm,
  handleSelect,
  selectedRows,
  setCurrentValue
}: {
  subTitle: string,
  data: DataType[],
  isEdit: boolean,
  isFetching: boolean,
  options: [],
  selectedGame: Game | null,
  renderDeleteButton?: boolean,
  gameTitleWidth?: string,
  handleGetGame: (newValue: Game) => Promise<void>,
  setSelectedGame: React.Dispatch<React.SetStateAction<Game | null>>,
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>,
  handleSelect: () => {},
  selectedRows: GameType[],
  setCurrentValue:()=>{}
    },
 ref: Ref<HTMLInputElement>) => {
  const handleFormApiErrors = useHandleFormApiErrors()
  const [deleteGame] = useDeleteGameMutation()
  const { data:userData, isLoading:isUserLoading } = useGetUserProfileQuery({}, { refetchOnMountOrArgChange: true })
  const [ updateUserPreferences ] = useUpdateUserPreferencesMutation()
  const { setAlert } = useAlert()
  const [deleteGames] = useDeleteGamesMutation()
  const [isopen, setIsOpen] = useState<boolean>(false)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const[isShow,setIsShow] =useState<boolean>(false)

  useEffect(() => {
    if(userData){
      setIsShow(userData?.user?.userPreferences?.showDeleteMessage)
    }
  }, [userData])

  const handleDelete = async (gameId: string) => {
    if (window.confirm("Are you sure you want to delete this game from your collection?")) {
      const response = await deleteGame(gameId)
      if ('error' in response) {
        handleFormApiErrors({
          error: {},
          defaultMessage: response.error.data?.error ?? "Something went wrong, please try again.",
          showFieldErrorsAsToast: true,
        })
      } else {
        setAlert({
          type: 'success',
          description: 'Game delete successfully.'
        })
      }
    }
  }

    const handleOpen = () => {
      if (selectedRows?.nightGames.length == 0) {
        setAlert({
          type: "error",
          description: "Please select any of the game to delete.",
        });
      }else{
        if(isShow){
          setIsOpen(true);
        }else{
          handleDeleteGames()
        }        
      } 
    };

    const handleClose = () => {
      setIsOpen(false);
    };
    
    const handleDeleteGames = async () => {
      const ids = selectedRows.nightGames.map((item) => item._id);
      const response = await deleteGames({ gameIdList: ids });
      if ('error' in response) {
        handleFormApiErrors({
          error: {},
          defaultMessage: response.error.data?.error ?? "Something went wrong, please try again.",
          showFieldErrorsAsToast: true,
        })
      } else {
        setAlert({
          type: 'success',
          description: 'Game deleted successfully.'
        })
        setIsOpen(false);
        setCurrentValue()
    }
  }

  const handleChange = (event) => {
    const payLoad = {
      userId: userData?.user?._id,
      data: {
        updatePreference: "showDeleteMessage",
      },
    };
    updateUserPreferences(payLoad);
    setIsChecked(event.target.checked);
}

  return (
    <div className='w-full'>
      <div className='block sm:flex justify-between gap-2 items-center'>
          <CustomAlert
            open={isopen}
            title={""}
            text={(selectedRows?.nightGames.length > 1) ?
              `Do you really want to remove ${selectedRows?.nightGames.length} games from your list?`
              :"Do you really want to remove game from your list?"
            }
            onAgree={handleDeleteGames}
            onClose={handleClose}
            titleAgree={"Delete"}
            titleCancel={"Cancel"}
            handleChange={handleChange}
            isChecked={isChecked}
            showCheckBox={true}
          />
        <SubTitle text={subTitle} />
        <GameSearchAutocomplete
          ref={ref}
          disabled={isFetching}
          options={options}
          value={selectedGame}
          noOptionsText="No games"
          setSelectedGame={setSelectedGame}
          handleGetGame={handleGetGame}
          setSearchTerm={setSearchTerm}
        />
      </div>
      <CustomTable
        data={data}
        isEdit={isEdit}
        isLoading={isFetching}
        renderDeleteButton={renderDeleteButton}
        gameTitleWidth={gameTitleWidth}
        handleDelete={handleDelete}
        selectedRows={selectedRows?.nightGames?.map((row) => row._id)}
        handleSelect={handleSelect}
        handleDeleteGames={handleOpen}
        remove={true}
      />
    </div>
  )
})

export default CollectionsTableWithSearch
