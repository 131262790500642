import { useMemo } from 'react'

import PageTitle from '../../components/PageTitle'
import GameNightsTable from '../../components/GameNightsTable'
import PageLoader from '../../components/PageLoader/PageLoader'
import Stat from '../../components/Stat'
import { useGetControlRoomInfoQuery } from '../../services/user'
import { GameNightEventType } from './types'

interface ExtendedGameNightEventType extends GameNightEventType {
  isCreator: boolean
}

function ControlRoomPage() {
  const { data, isLoading, currentData } = useGetControlRoomInfoQuery({}, { refetchOnMountOrArgChange: true })

  const upcomingHostedGameEvents: ExtendedGameNightEventType[] = useMemo(() => data?.hostedEvents
    .filter((gameEvent: ExtendedGameNightEventType) =>
      new Date(gameEvent.scheduledDate) > new Date()), [data?.hostedEvents])

  const upcomingInvitedGameEvents: ExtendedGameNightEventType[] = useMemo(() => data?.participatedEvents
    .filter((gameEvent: ExtendedGameNightEventType) =>
      new Date(gameEvent.scheduledDate) > new Date()), [data?.participatedEvents])

  const gameNightsHosted: ExtendedGameNightEventType[] = useMemo(() => data?.hostedEvents
    .filter((gameEvent: ExtendedGameNightEventType) =>
      new Date(gameEvent.scheduledDate) < new Date()), [data?.hostedEvents])

  const activeGameEvents: ExtendedGameNightEventType[] = useMemo(() => data?.hostedEvents
    .filter((gameEvent: ExtendedGameNightEventType) =>
      !["cancelled", "archived"].includes(gameEvent.status)), [data?.hostedEvents])

  const gameNightsParticipated: ExtendedGameNightEventType[] = useMemo(() => data?.participatedEvents
    .filter((gameEvent: ExtendedGameNightEventType) =>
      new Date(gameEvent.scheduledDate) < new Date() && (gameEvent.guests as string[]).includes(data.userId)), [data?.participatedEvents])

  const archivedEvents: ExtendedGameNightEventType[] = useMemo(() => data?.hostedEvents
    .filter((gameEvent: ExtendedGameNightEventType) =>
      ["cancelled", "archived"].includes(gameEvent.status)), [data?.hostedEvents])

  if (isLoading && !currentData) return <PageLoader />

  const yourGameNightTableData = [...activeGameEvents, ...upcomingInvitedGameEvents]

  return (
    <div className='max-w-[800px] w-full mx-auto flex flex-col gap-4 items-center'>
      <PageTitle title='Control Room' />
      <div className='flex flex-col sm:flex-row w-full items-start justify-center col-span-3 gap-8'>
        <Stat name="Upcoming Game Nights" value={upcomingHostedGameEvents.length + upcomingInvitedGameEvents.length} />
        <Stat name="Game Nights hosted" value={gameNightsHosted.length} />
        <Stat name="Game Nights participated" value={gameNightsParticipated.length} />
      </div>

      <div className='w-full py-6'>
        <div className='flex items-center gap-3 w-full'>
          <p className='text-white2 text-[40px] font-bold font-teko text-left'>Your Game Nights</p>
        </div>
        {
          yourGameNightTableData.length === 0 ? (
            <div className='bg-table p-4 relative rounded-xl scrollbar-custom'>
              <p className="text-white3 whitespace-nowrap font-poppins font-normal py-4 pr-3 text-center text-base">{isLoading ? '' : 'No data found'}</p>
            </div>
          ) : <GameNightsTable data={yourGameNightTableData} />
        }
      </div>

      <div className='w-full py-6'>
        <div className='flex items-center gap-3 w-full'>
          <p className='text-white2 text-[40px] font-bold font-teko text-left leading-none'>Archived Game Nights</p>
        </div>
        {
          archivedEvents.length === 0 ? (
            <div className='bg-table p-4 relative rounded-xl scrollbar-custom'>
              <p className="text-white3 whitespace-nowrap font-poppins font-normal py-4 pr-3 text-center text-base">{isLoading ? '' : 'No data found'}</p>
            </div>
          ) :
            <GameNightsTable data={archivedEvents} archived />
        }
      </div>
    </div>
  )
}

export default ControlRoomPage;
