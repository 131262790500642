import { StatProps } from './types'

function Stat({ name, value }: StatProps) {
    return (
        <div className='flex flex-col bg-table w-full min-h-24 p-4 rounded-xl'>
            <p className='text-white2 text-[40px] font-bold font-teko text-left'>{value}</p>
            <p className='text-white3 font-normal text-base font-poppins'>{name}</p>
        </div>
    )
}

export default Stat
