import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import PageTitle from '../../components/PageTitle'
import Progress from '../../components/Progress'
import GameSetUpAboutPage from './GameSetUpAbout'
import GameSetUpCollectionPage from './GameSetUpCollection'
import GameSetUpGamesPage from './GameSetUpGames'
import GameSetUpFriendsPage from './GameSetUpFriends'
import GameSetUpReviewPage from './GameSetUpReview'
import useCurrentValue from '../../hooks/useCurrentValue'
import { useGetGameEventQuery } from '../../services/event'
import PageLoader from '../../components/PageLoader/PageLoader'
import { history } from '../../main/Router'
import { useGetCollectionsCountQuery, useGetCollectionsQuery } from '../../services/collection'
import { DataType } from '../../components/GamesTable/types'
import { GuestDataType } from '../../components/ParticipantsTable/types'

function GameSetUpPage({ editMode }: { editMode?: boolean }) {
  const params = useParams()
  const location = useLocation();

  const { setCurrentGameSetupValue } = useCurrentValue()
  const [currentStep, setCurrentStep] = useState('about')
  const { data } = useGetCollectionsCountQuery({}, { refetchOnMountOrArgChange: true })
  const { data: gameEventData, isLoading } = useGetGameEventQuery(params.eventId, { skip: !params.eventId })
  const getCollectionsResponse = useGetCollectionsQuery({}, { refetchOnMountOrArgChange: true })

  useEffect(() => {
    if (location.state?.currentStep) {
      setCurrentStep(location.state.currentStep)
    }
  }, [location.state?.currentStep])

  useEffect(() => {
    if (getCollectionsResponse.data) {
      setCurrentGameSetupValue({ collectionGames: getCollectionsResponse.data?.collectionGames ?? [] })
    }
  }, [getCollectionsResponse.data])

  useEffect(() => {
    if (editMode && gameEventData?.gameEvent) {
      if (gameEventData?.gameEvent?.finalized) {
        history.push(`/game-nights/${gameEventData?.gameEvent._id}`)
      }
      const { gameEvent } = gameEventData
      setCurrentGameSetupValue({
        bggProfile: gameEvent.bggProfile,
        title: gameEvent.title,
        description: gameEvent.description,
        scheduledDate: gameEvent.scheduledDate,
        time: gameEvent.time,
        location: gameEvent.location,
        nightGames: gameEvent.gameList.map((game: DataType) => game.gameId),
        guests: gameEvent.guests.map((guest: GuestDataType) => guest.email),
        gameList: gameEvent.gameList,
      })
    } else {
      setCurrentGameSetupValue({
        bggProfile: "",
        title: "",
        description: "",
        scheduledDate: "",
        time: "",
        location: "",
        nightGames: [],
        guests: [],
        gameList: [],
      })
    }
  }, [editMode, gameEventData])

  const isNewUser = !data?.collectionExists

  if (isLoading) return <PageLoader />

  return (
    <div className='max-w-[800px] w-full mx-auto flex flex-col gap-4 items-center px2'>
      <PageTitle title='Set up your first game night' />
      <Progress isNewUser={isNewUser} step={currentStep} />
      {currentStep === 'about' && (
        <GameSetUpAboutPage
          isNewUser={isNewUser}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      )}
      {currentStep === 'collection' && (
        <GameSetUpCollectionPage
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      )}
      {currentStep === 'games' && (
        <GameSetUpGamesPage
          isNewUser={isNewUser}
          currentStep={currentStep}
          isLoading={getCollectionsResponse.isLoading}
          setCurrentStep={setCurrentStep}
          editMode={editMode}
        />
      )}
      {currentStep === 'friends' && (
        <GameSetUpFriendsPage
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      )}
      {currentStep === 'review' && (
        <GameSetUpReviewPage
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          editMode={editMode}
        />
      )}
    </div>
  )
}

export default GameSetUpPage
