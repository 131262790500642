import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../../types'
import { InitialStateTypes } from './types'

export const initialState: InitialStateTypes = {
  title: "",
  description: "",
  scheduledDate: "",
  time: "",
  location: "",
  bggProfile: "",
  collectionGames: [],
  nightGames: [],
  guests: [],
};

export const gameSetupSlice = createSlice({
  name: "gameSetup",
  initialState,
  reducers: {
    addData: (state, action: PayloadAction<InitialStateTypes | undefined>) => {
      if (!action.payload) {
        return state;
      }
      return { ...state, ...action.payload }
    },
    resetGameData: () => initialState
  },
});

export const { addData, resetGameData } = gameSetupSlice.actions

export const gameSetupSelectors = {
  gameSetup: (state: RootState) => state.gameSetup,
}

export default gameSetupSlice.reducer

export * from "./types";
