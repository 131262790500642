import { useMemo } from 'react'
import { format } from 'date-fns'
import { useParams } from 'react-router'

import Button from '../../components/Button'
import EditImg from '../../assets/images/edit.png'
import PageTitle from '../../components/PageTitle'
import ParticipantsTable from '../../components/ParticipantsTable'
import GamesTable from '../../components/GamesTable'
import { useCancelGameNightMutation, useFinalizeVotingMutation, useGetGameEventQuery } from '../../services/event'
import useSession from '../../hooks/useSession'
import PageLoader from '../../components/PageLoader/PageLoader'
import { history } from '../../main/Router'
import { Game } from '../../components/GamesTable/types'
import useHandleFormApiErrors from '../../hooks/useHandleFormApiErrors'

function GameNightPage() {
  const { eventId } = useParams()
  const { user } = useSession()
  const [cancelGameNight, cancelGameNightResponse] = useCancelGameNightMutation({})
  const [finalizeVoting, finalizeVotingResponse] = useFinalizeVotingMutation({})
  const { data, isLoading } = useGetGameEventQuery(eventId)
  const handleFormApiErrors = useHandleFormApiErrors()
  const gameEvent = useMemo(() => data?.gameEvent, [data])

  const isHost = gameEvent?.isHost
  if (isLoading) return <PageLoader />
  if (!gameEvent) history.push("/404")

  const isVotingPending = gameEvent?.status === "pending"
  console.log({ gameEvent, eventId, user })

  const handleCancelGameNight = async () => {
    if (window.confirm("Are you sure that you want to cancel the game night? Please note that this is an irreversible action.")) {
      const response = await cancelGameNight({ eventId: gameEvent?._id, cancelNote: "Testing" })
      if ('error' in response) {
        handleFormApiErrors({
          error: {},
          // @ts-ignore
          defaultMessage: response.error.data?.error ?? "Something went wrong, please try again.",
          showFieldErrorsAsToast: true,
        })
      } else {
        history.push("/control-room")
      }
    }
  }

  const handleFinalizeVoting = async () => {
    if (window.confirm("Are you sure that you want to finalize the game night? Please note that this is an irreversible action.")) {
      const response = await finalizeVoting({ eventId: gameEvent?._id })
      if ('error' in response) {
        handleFormApiErrors({
          error: {},
          // @ts-ignore
          defaultMessage: response.error?.data?.error ?? "Something went wrong, please try again.",
          showFieldErrorsAsToast: true,
        })
      }
    }
  }

  return (
    <div className='max-w-[800px] w-full mx-auto flex flex-col gap-4 items-start'>
      <PageTitle title={gameEvent?.title} />
      <div className='flex flex-col w-full items-start'>
        <div className='flex items-center gap-3 w-full'>
          <p className='text-white2 text-[40px] font-bold font-teko text-left'>About</p>
          {
            isHost && isVotingPending && (
              <img
                src={EditImg}
                alt='edit'
                onClick={() => {
                  history.push(`/game-nights/${gameEvent?._id}/edit`, { currentStep: 'about' })
                }}
                className='cursor-pointer'
              />
            )
          }
        </div>
        <p className='text-white2 font-semibold text-base font-poppins py-2'>Description</p>
        <p className='max-w-[584px] text-white2 font-normal text-base font-poppins'>
          {gameEvent?.description}
        </p>
        <div className='flex w-full'>
          <div className='w-1/2 max-w-[292px]'>
            <p className='text-white2 font-semibold text-base font-poppins py-2'>Date</p>
            <p className='text-white2 font-normal text-base font-poppins'>
              {format(new Date(gameEvent?.scheduledDate), "MM/dd/yyyy")}
            </p>
          </div>
          <div>
            <p className='text-white2 font-semibold text-base font-poppins py-2'>
              Time
            </p>
            <p className='text-white2 font-normal text-base font-poppins'>
              {format(new Date(gameEvent?.time), "hh:mm aa")}
            </p>
          </div>
        </div>
        <p className='text-white2 font-semibold text-base font-poppins py-2'>Place</p>
        <p className='text-white2 font-normal text-base font-poppins'>
          {gameEvent?.location}
        </p>
      </div>

      <div className='w-full'>
        <div className='flex items-center gap-3 w-full'>
          <p className='text-white2 text-[40px] font-bold font-teko text-left'>Participants</p>
          {
            isHost && isVotingPending && (
              <img
                src={EditImg}
                alt='edit'
                onClick={() => {
                  history.push(`/game-nights/${gameEvent?._id}/edit`, { currentStep: 'friends' })
                }}
                className='cursor-pointer'
              />
            )
          }
        </div>
        <ParticipantsTable data={gameEvent?.guests} />
      </div>

      <div className='flex flex-col w-full items-start'>
        <div className='flex items-center gap-3 w-full'>
          <p className='text-white2 text-[40px] font-bold font-teko text-left'>Games</p>
          {
            isHost && isVotingPending && (
              <img
                src={EditImg}
                alt='edit'
                onClick={() => {
                  history.push(`/game-nights/${gameEvent?._id}/edit`, { currentStep: 'games' })
                }}
                className='cursor-pointer'
              />
            )
          }
        </div>
        <GamesTable
          data={gameEvent?.gameList}
          eventId={gameEvent?._id}
          isVotingPending={isVotingPending}
          isLoading={finalizeVotingResponse.isLoading || cancelGameNightResponse.isLoading}
          gamePickedIds={gameEvent?.gamePicked.map((game: Game) => game._id)}
        />
        <div className='flex gap-4 flex-wrap pb-8'></div>
      </div>
      {
        isHost && isVotingPending && (
          <div className='flex w-full justify-between mb-6 gap-4'>
            <Button
              text='Cancel Game Night'
              type='button'
              btnFor='cancel'
              disabled={finalizeVotingResponse.isLoading || cancelGameNightResponse.isLoading}
              onClick={handleCancelGameNight}
            />
            <Button
              text='Finalize Voting'
              type='button'
              disabled={finalizeVotingResponse.isLoading || cancelGameNightResponse.isLoading}
              onClick={handleFinalizeVoting}
            />
          </div>
        )
      }
    </div>
  )
}

export default GameNightPage;
