import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useParams } from 'react-router-dom';

import Button from '../../components/Button'
import Description from '../../components/Description'
import SubTitle from '../../components/SubTitle'
import CustomTable from '../../components/CustomTable'
import useCurrentValue from '../../hooks/useCurrentValue'
import { GameSetUpProps } from './types'
import useHandleFormApiErrors from '../../hooks/useHandleFormApiErrors'
import { useDeleteGameMutation } from '../../services/collection'
import { useAlert } from '../../contexts/AlertContext'
import GameSearchAutocomplete from '../../components/GameSearchAutocomplete';
import {
  useImportGameMutation,
  useLazySearchGamesQuery,
  useUpdateGameEventMutation,
} from '../../services/event';

interface GameOption {
  id: string;
  name: string;
}

function GameSetUpGamesPage({ isNewUser, isLoading, editMode, setCurrentStep }: GameSetUpProps) {
  const { currentGameSetupValue, setCurrentGameSetupValue } = useCurrentValue()
  const handleFormApiErrors = useHandleFormApiErrors()
  const [deleteGame] = useDeleteGameMutation()
  const { setAlert } = useAlert()
  const params = useParams()
  const ref = useRef<HTMLInputElement>(null);

  const { collectionGames, nightGames } = currentGameSetupValue

  const handleSelect = (selectedRows: string[]) => {
    setCurrentGameSetupValue({
      nightGames: selectedRows.map((id: string) => collectionGames.find((item) => item._id === id)).filter(Boolean)
    })
  }

  const handleGoToNextStep = () => {
    if (nightGames.length < 1) {
      handleFormApiErrors({
        error: {},
        defaultMessage: "Please select at least one game.",
        showFieldErrorsAsToast: true,
      })
    } else if (nightGames.length > 20) {
      handleFormApiErrors({
        error: {},
        defaultMessage: "Games count cannot be more than 20.",
        showFieldErrorsAsToast: true,
      })
    } else {
      setCurrentStep('friends')
    }
  }

  const [updateGameEvent] = useUpdateGameEventMutation();

  const [searchGames, { data: games }] = useLazySearchGamesQuery();
  const [importGame] = useImportGameMutation();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGame, setSelectedGame] = useState<GameOption | null>(null);

  const [debouncedSearchTerm] = useDebounce(searchTerm, 200);

  useEffect(() => {
    const fetchGames = async () => {
      if (!debouncedSearchTerm) return;
      const response = await searchGames(debouncedSearchTerm);
      if (response.error) {
        handleFormApiErrors({
          error: {},
          defaultMessage: 'Something went wrong, please try again.',
          showFieldErrorsAsToast: true,
        });
      }
    };
    fetchGames();
  }, [debouncedSearchTerm]);

  const handleClearAutocompleteInput = () => {
    const clearInputButton = ref.current?.getElementsByClassName(
      'MuiAutocomplete-clearIndicator'
    )[0] as HTMLElement;
    if (clearInputButton) clearInputButton.click();
  };

  const handleGetGame = async (newValue: GameOption) => {
    if (!newValue || !newValue.id) return;
    const response = await importGame(newValue.id);
    if ('error' in response) {
      handleFormApiErrors({
        error: {},
        defaultMessage: 'Something went wrong, please try again.',
        showFieldErrorsAsToast: true,
      });
    } else {
      const existingGame = currentGameSetupValue.collectionGames.find(
        (game) => game._id === response.data.gameDetails._id
      );
      if (existingGame) {
        setAlert({
          type: 'info',
          description: 'Game already exists in the collection.',
        });
      } else {
        setCurrentGameSetupValue({
          collectionGames: [
            response.data.gameDetails,
            ...currentGameSetupValue.collectionGames,
          ],
        });
        handleClearAutocompleteInput();
        setAlert({
          type: 'success',
          description: 'Game added successfully.',
        });
      }
    }
  };

  const handleDeleteGame = async (gameId: string) => {
    if (
      window.confirm(
        'Are you sure you want to delete this game from your collection?'
      )
    ) {
      const response = await deleteGame(gameId);
      if ('error' in response) {
        handleFormApiErrors({
          error: {},
          defaultMessage:
            response.error.data?.error ??
            'Something went wrong, please try again.',
          showFieldErrorsAsToast: true,
        });
      } else {
        const selectedRows = currentGameSetupValue.nightGames.map(
          (row) => row._id
        );
        const nightGames = selectedRows
          .map((id: string) =>
            collectionGames.find(
              (item) => item._id === id && item._id !== gameId
            )
          )
          .filter(Boolean);
        setCurrentGameSetupValue({ nightGames });
        const gameList = nightGames.map((game) => ({
          gameId: game._id,
          yesVote: [],
          noVote: [],
          ...currentGameSetupValue?.gameList?.find(
            (item) => item.gameId._id === game._id
          ),
        }));
        const gameData = {
          ...Object.fromEntries(
            Object.entries(currentGameSetupValue).filter(
              ([key]) => key !== 'collectionGames' && key !== 'nightGames'
            )
          ),
          gameList,
        };
        await updateGameEvent({ data: gameData, eventId: params.eventId });
        setAlert({
          type: 'success',
          description: 'Game delete successfully.',
        });
      }
    }
  };

  return (
    <div className='w-full mx-auto flex flex-col gap-4 items-center'>
      <Description text="To make it easier for you to setup your game nights, please add the games you own to your collection. Don't worry if you miss some games, you can always update them later in your profile." />
      
        
      <div className='w-full'>
        <div className='block sm:flex justify-between gap-2 items-center'>
          <SubTitle text='Add games to the game night' />
          {editMode ? (
            <GameSearchAutocomplete
              ref={ref}
              options={
                games?.data ? games.data.map((game: GameOption) => game) : []
              }
              value={selectedGame}
              noOptionsText="No games"
              setSelectedGame={setSelectedGame}
              handleGetGame={handleGetGame}
              setSearchTerm={setSearchTerm}
            />
          ) : null}
        </div>
        <CustomTable
          data={collectionGames}
          isEdit
          renderDeleteButton={editMode}
          handleDelete={handleDeleteGame}
          isLoading={!!isLoading}
          selectedRows={currentGameSetupValue.nightGames.map((row) => row._id)}
          handleSelect={handleSelect}
          remove={false}
        />
      </div>
      <div className='flex w-full justify-between'>
        <Button
          text='Previous'
          type='button'
          btnFor='prev'
          onClick={() => {
            setCurrentStep(isNewUser ? 'collection' : 'about')
          }}
        />
        <Button
          text='Next'
          type='button'
          disabled={!!isLoading}
          onClick={handleGoToNextStep}
        />
      </div>
    </div>
  )
}

export default GameSetUpGamesPage
