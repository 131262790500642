export const MAX_UPLOAD_SIZE = 10485760; // 10MB
export const UPLOADING_TIME_LIMIT = 60;
export const DOCUMENT_ACCEPT_TYPES = {
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
}

export const AUTH0_CONNECTIONS = {
    USERNAME_PASSWORD_AUTHENTICATION: "Username-Password-Authentication"
}
