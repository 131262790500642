import { twMerge } from "tailwind-merge"

function SubTitle({ text, className }: { text: string, className?: string }) {
  return (
    <div className={twMerge("w-full text-white2 text-2xl font-bold font-teko mb-4 text-left", className)}>
      {text}
    </div>
  )
}

export default SubTitle
