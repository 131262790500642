import { useDispatch, useSelector } from 'react-redux'
import {
  GameSetupState,
  addData,
  resetGameData,
  gameSetupSelectors,
} from '../../state/slices/gameSetup'

const useCurrentValue = () => {
  const currentGameSetupValue: GameSetupState = useSelector(gameSetupSelectors.gameSetup)
  const dispatch = useDispatch()

  const setCurrentGameSetupValue = (values: any) => dispatch(addData(values))

  const resetGameSetup = () => dispatch(resetGameData())

  return { currentGameSetupValue, setCurrentGameSetupValue, resetGameSetup }
};

export default useCurrentValue;
