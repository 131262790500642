import Button from '../Button'
import { UploadProps } from './types'

function UploadField({
  label,
  buttonLabel,
  placeholder,
  disabled,
  value = '',
  handleChange = () => null,
  handleUpload = () => null,
}: UploadProps) {
  return (
    <div className='w-full pt-0 pb-2'>
      <p className='font-semibold font-poppins text-[16px] text-white3 text-left mb-2'>
        {label}
      </p>
      <div className='relative flex flex-col md:flex-row'>
        <input
          className='flex-1 bg-input text-white3 p-3 rounded-t-lg md:rounded-l-lg md:rounded-t-none border border-border outline-none'
          type='text'
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleChange}
          value={value}
          name='upload'
        />
        <Button
          disabled={disabled}
          onClick={handleUpload}
          text={buttonLabel}
          className='bg-button uppercase font-semibold font-poppins text-sm sm:text-base md:text-[18px] text-white1 text-center flex items-center sm:py-2 md:px-6 my-0 rounded-none md:rounded-r-[100px] cursor-pointer'
        />
      </div>
    </div>
  )
}

export default UploadField
