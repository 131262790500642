import { useState } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, List, ListItem, SwipeableDrawer } from '@mui/material';
import { useLocation } from 'react-router-dom';

import useSession from '../hooks/useSession'
import LogoImg from '../assets/images/logo.png'
import { history } from '../main/Router'
import { useAuth } from '../contexts/AuthContext';
import { useGetUserProfileQuery } from '../services/user';

const navigationLinks = [
  { name: "Get in touch", href: "https://discord.com/invite/abDpR6nSxW" },
  { name: "Feedback", href: "https://bga.canny.io/feedback" },
  { name: "Control Room", href: "/control-room" },
  { name: "Collection", href: "/collections" },
  { name: "NEW GAME NIGHT", href: "/" },
];

const mobileMenuLinks = [
  ...navigationLinks,
  // { name: "My Profile", href: "/profile/activities" },
  { name: "Settings", href: "/profile/settings" },
]

function Header() {
  const { user } = useSession()
  const { signOut } = useAuth()
  const location = useLocation()

  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data } = useGetUserProfileQuery({}, { skip: !user })

  return (
    <div className='flex justify-between w-full mx-auto py-3 max-w-[1400px] px-0 md:px-8 pt-4'>
      <img onClick={() => history.push('https://www.boardgamearc.com/')} src={LogoImg} className='cursor-pointer' />
      {user &&
        <>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <div className='flex items-center font-poppins text-[15px] gap-8'>
              {
                navigationLinks.map(({ name, href }) => (
                  name == "Feedback" ? 
                  <p
                    key={name}
                    onClick={() => window.open(href, '_blank')}
                    className={`cursor-pointer ${href === location.pathname ? 'hidden sm:flex text-button font-bold hover:opacity-90' : 'text-white hover:text-white4 font-medium'}`}
                  >
                    {name}
                  </p> :
                  <p
                    key={name}
                    onClick={() => history.push(href)}
                    className={`cursor-pointer ${href === location.pathname ? 'hidden sm:flex text-button font-bold hover:opacity-90' : 'text-white hover:text-white4 font-medium'}`}
                  >
                    {name}
                  </p>
                ))
              }
              <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Avatar
                      sx={{ width: 32, height: 32 }}
                      src={data?.user?.avatar || user?.picture}
                      alt={user.nickname.slice(0, 1).toUpperCase()}
                    >
                      {user.nickname.slice(0, 1).toUpperCase()}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                  paper: {
                    style: {
                      backgroundColor: '#1B2132',
                      color: '#DDDDDD',
                    },
                  }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={() => history.push('/profile/settings')} sx={{ fontSize: 14, fontWeight: 600 }}>
                  MY PROFILE
                </MenuItem>
                {/* <MenuItem onClick={() => history.push('/profile/settings')} sx={{ fontSize: 14, fontWeight: 600 }}>
                  SETTINGS
                </MenuItem> */}
                <MenuItem onClick={signOut} sx={{ fontSize: 14, fontWeight: 600, color: 'red' }}>
                  LOG OUT
                </MenuItem>
              </Menu>
            </div>
          </Box>

          <IconButton sx={{ display: { xs: 'block', sm: 'none' } }} onClick={() => setOpenMenu(true)}>
            <MenuIcon />
          </IconButton>
          <SwipeableDrawer
            anchor="top"
            open={openMenu}
            onOpen={() => setOpenMenu(true)}
            onClose={() => setOpenMenu(false)}
          >
            <div className='p-2 bg-darkBlue text-white3'>
              <div
                onClick={() => setOpenMenu(false)}
                role="button"
                className='flex justify-between w-full py-3 px-0 md:px-8 pt-4'
                tabIndex={0}
              >
                <img onClick={() => history.push('/')} src={LogoImg} className='cursor-pointer' />
                <IconButton onClick={() => setOpenMenu(false)}>
                  <CloseIcon />
                </IconButton>
              </div>
              <Divider sx={{ mt: 2 }} />
              <List>
                {mobileMenuLinks.map(({ name, href }) => (
                  <ListItem
                    key={name}
                    onClick={() => {
                      history.push(href)
                      setOpenMenu(false)
                    }}
                  >
                    <span className={`text-center w-full ${href === location.pathname ? 'text-button' : 'text-white3'}`}>{name}</span>
                  </ListItem>
                ))}
                <ListItem
                  onClick={signOut}
                  sx={{ fontSize: 14, fontWeight: 600, color: 'red', display: 'block', textAlign: 'center' }}
                >
                  LOG OUT
                </ListItem>
              </List>
            </div>
          </SwipeableDrawer>
        </>
      }
    </div>
  )
}

export default Header
