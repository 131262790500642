import { MouseEvent, useState } from "react";
import { format } from "date-fns";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";

import YesImg from "../../assets/images/yes.png";
import NoImg from "../../assets/images/no.png";
import PendingImg from "../../assets/images/pending.png";
import { GameListType, GameNightEventType } from "../../pages/ControlRoom/types";
import { useArchiveGameEventMutation, useDeleteGameEventMutation, useDuplicateGameEventsMutation } from "../../services/event";
import { history } from "../../main/Router";
import useHandleFormApiErrors from "../../hooks/useHandleFormApiErrors";

const getVotesCount = (games: GameListType[]) => {
  return games.reduce((accumulator: number, game: GameListType) =>
    accumulator + (game.yesVote.length + game.noVote.length), 0);
};

function GameNightsTable(
  {
    data,
    archived,
  }:
    {
      data: (GameNightEventType & { isCreator: boolean })[],
      archived?: boolean,
    }
) {
  const [duplicateGameEvent] = useDuplicateGameEventsMutation({})
  const [deleteGameEvent] = useDeleteGameEventMutation({})
  const [archiveGameEvent] = useArchiveGameEventMutation({})
  const handleFormApiErrors = useHandleFormApiErrors()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<null | GameNightEventType>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>, row: GameNightEventType) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const headerStyle =
    "text-white1 whitespace-nowrap font-bold font-poppins text-sm px-2 py-4 text-left min-w-24";
  const rowStyle =
    "text-white3 whitespace-nowrap font-poppins font-normal text-sm py-5 px-2";

  const handleDuplicateGameEvent = async () => {
    if (selectedRow?._id) {
      handleClose()
      const response = await duplicateGameEvent({ eventId: selectedRow._id })
      if ('error' in response) {
        handleFormApiErrors({
          error: {},
          defaultMessage: response.error.data?.error ?? "Something went wrong, please try again.",
          showFieldErrorsAsToast: true,
        })
      }
    }
  }

  const handleDeleteGameEvent = async () => {
    if (selectedRow?._id) {
      if (window.confirm("Are you sure that you want to cancel the game night? Please note that this is an irreversible action.")) {
        handleClose()
        const response = await deleteGameEvent({ eventId: selectedRow._id })
        if ('error' in response) {
          handleFormApiErrors({
            error: {},
            defaultMessage: response.error.data?.error ?? "Something went wrong, please try again.",
            showFieldErrorsAsToast: true,
          })
        }
      }
    }
  }

  const handleArchiveGameEvent = async () => {
    if (selectedRow?._id) {
      handleClose()
      const response = await archiveGameEvent({ eventId: selectedRow._id })
      if ('error' in response) {
        handleFormApiErrors({
          error: {},
          defaultMessage: response.error.data?.error ?? "Something went wrong, please try again.",
          showFieldErrorsAsToast: true,
        })
      }
    }
  }

  const handleEditGameNight = () => history.push(`/game-nights/${selectedRow?._id}/edit`)

  return (
    <div className=" bg-table p-4 rounded-xl">
      <div className="flow-root">
        <div className="-mx-2 -my-2 overflow-x-auto scrollbar-custom sm:-mx-2">
          <div className="inline-block min-w-full max-h-[367px] align-middle">
            <div className="relative">
              <table className="min-w-full table-fixed divide-y divide-input">
                <thead>
                  <tr>
                    <th scope="col" className={`${headerStyle}`}>
                      Game Night
                    </th>
                    <th scope="col" className={`${headerStyle}`}>
                      Date
                    </th>
                    <th scope="col" className={`${headerStyle} min-w-16`}>
                      Time
                    </th>
                    <th scope="col" className={headerStyle}>
                      Location
                    </th>
                    <th scope="col" className={`${headerStyle} text-center`}>
                      #participants
                    </th>
                    <th scope="col" className={`${headerStyle} text-center`}>
                      Voting
                    </th>
                    <th scope="col" className={`${headerStyle}`}>
                      Games
                    </th>
                    <th scope="col">
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-input">
                  {data.map((gameEvent: (GameNightEventType & { isCreator: boolean })) => {
                    const numberOfVotes = getVotesCount(gameEvent.gameList)
                    const gameEventId = gameEvent._id

                    function handleRedirectToGameNight() {
                      history.push(`/game-nights/${gameEventId}`)
                    }

                    return (
                      <tr key={gameEventId} className="cursor-pointer">
                        <td className={`${rowStyle} text-wrap`} onClick={handleRedirectToGameNight}>
                          {gameEvent.title}
                        </td>
                        <td className={rowStyle} onClick={handleRedirectToGameNight}>{format(new Date(gameEvent.scheduledDate), "dd.MM.yyyy")}</td>
                        <td className={rowStyle} onClick={handleRedirectToGameNight}>{format(new Date(gameEvent.time), "hh:mm aa")}</td>
                        <td className={rowStyle} onClick={handleRedirectToGameNight}>{gameEvent.location}</td>
                        <td className={rowStyle} onClick={handleRedirectToGameNight}>{gameEvent.guests.length}</td>
                        <td align="center" onClick={handleRedirectToGameNight}>
                          <img
                            src={
                              gameEvent.finalized ? YesImg
                                : numberOfVotes > 0
                                  ? NoImg
                                  : PendingImg
                            }
                          />
                        </td>
                        <td align="center" onClick={handleRedirectToGameNight}>
                          <div className={`${rowStyle}`}>
                            {gameEvent.gameList.slice(0, 3).map(
                              ({ gameId }, i) =>
                                <p key={gameId?._id} className={`py-0 text-left`}>
                                  {gameId?.title} {i === 2 ? "..." : ","}
                                </p>
                            )}
                          </div>
                        </td>
                        <td className="sticky right-0 bg-table">
                          {
                            gameEvent.isCreator ? (
                              <div>
                                <IconButton
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls={open ? 'long-menu' : undefined}
                                  aria-expanded={open ? 'true' : undefined}
                                  aria-haspopup="true"
                                  onClick={(event) => { handleClick(event, gameEvent) }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                {
                                  archived ? (
                                    <Menu
                                      id="archived-event-menu"
                                      MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                      }}
                                      anchorEl={anchorEl}
                                      open={open}
                                      onClose={handleClose}
                                      slotProps={{
                                        paper: {
                                          style: {
                                            backgroundColor: '#1B2132',
                                            color: '#DDDDDD'
                                          },
                                        }
                                      }}
                                    >
                                      <MenuItem onClick={handleDuplicateGameEvent}>
                                        DUPLICATE
                                      </MenuItem>
                                    </Menu>
                                  ) : (
                                    <Menu
                                      id="event-menu"
                                      MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                      }}
                                      anchorEl={anchorEl}
                                      open={open}
                                      onClose={handleClose}
                                      slotProps={{
                                        paper: {
                                          style: {
                                            backgroundColor: '#1B2132',
                                            color: '#DDDDDD'
                                          },
                                        }
                                      }}
                                    >
                                      {
                                        !gameEvent.finalized && (
                                          <MenuItem onClick={handleEditGameNight}>
                                            EDIT
                                          </MenuItem>
                                        )
                                      }
                                      {/* <MenuItem onClick={handleDuplicateGameEvent}>
                                        DUPLICATE
                                      </MenuItem> */}
                                      {
                                        gameEvent.status === "cancelled" || new Date(gameEvent.scheduledDate) < new Date() && (
                                          <MenuItem onClick={handleArchiveGameEvent}>
                                            ARCHIVE
                                          </MenuItem>
                                        )
                                      }
                                      <MenuItem onClick={handleDeleteGameEvent}>
                                        DELETE
                                      </MenuItem>
                                    </Menu>
                                  )}
                              </div>
                            ) : null
                          }
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameNightsTable;
