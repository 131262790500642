import bgaApi from '../../state/api'

const collectionService = bgaApi.injectEndpoints({
    endpoints: build => ({
        getCollectionsCount: build.query({
            query: () => ({
                url: `/collections/count`,
                method: 'GET',
            }),
        }),
        getCollections: build.query({
            query: () => ({
                url: `/collections`,
                method: 'GET',
            }),
            providesTags: ['GetCollections']
        }),
        deleteGame: build.mutation({
            query: (gameId: string) => ({
                url: `/collections/${gameId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['GetCollections']
        }),

        deleteGames: build.mutation({
            query: (gameIds: []) => ({
                url: "/collections/deleteMany",
                method: 'POST',
                body: gameIds
              }),
            invalidatesTags: ['GetCollections']
        }),
    }),
})

export default collectionService

export const {
    useGetCollectionsCountQuery,
    useGetCollectionsQuery,
    useDeleteGameMutation,
    useDeleteGamesMutation
} = collectionService

export const {
    endpoints: {
        getCollectionsCount,
        getCollections,
        deleteGame,
        deleteGames
    },
} = collectionService
