import { SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function ProfileTabs({ selectedPanelIndex, handleChange }: {
    selectedPanelIndex: number,
    handleChange: (event: SyntheticEvent, newValue: number) => void
}) {
    return (
        <Box sx={{ borderBottom: 1, borderColor: '#5A5F6B' }}>
            <Tabs
                value={selectedPanelIndex}
                // onChange={handleChange}
                TabIndicatorProps={{ sx: { height: 4 } }}
                textColor="primary"
                indicatorColor="primary"
                aria-label="basic tabs example"
            >
                {/* <Tab
                    label="Activities"
                    {...a11yProps(0)}
                    sx={{
                        color: '#DDDDDD',
                        fontWeight: 800,
                    }}
                    component={Link}
                    to="/profile/activities"
                /> */}
                <Tab
                    label="Settings"
                    {...a11yProps(1)}
                    sx={{ color: '#DDDDDD', fontWeight: 800 }}
                    component={Link}
                    to="/profile/settings"
                />
            </Tabs>
        </Box>
    )
}

export default ProfileTabs
