import ReactGA from 'react-ga4';

const Analytics = () => {
    ReactGA.initialize([
        {
            trackingId: "G-PZ73W4RJ2S",
            //   gaOptions: {...}, // optional
            //   gtagOptions: {...}, // optional
        },
        {
            trackingId: "your second GA measurement id",
        },
    ]);
    return (<div></div>)
}
export default Analytics;