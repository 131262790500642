import ReactDOM from "react-dom/client";

import App from "./app/index.tsx";

import { ThemeProvider } from './app/themes/ThemeProvider.tsx';

import "./index.css";

ReactDOM.createRoot(document.getElementById('root')!).render(
    <ThemeProvider>
      <App />
    </ThemeProvider>
)