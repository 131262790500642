import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useDebounce } from "use-debounce";

import Button from '../../components/Button'
import Description from '../../components/Description'
import SubTitle from '../../components/SubTitle'
import UploadField from '../../components/UploadField'
import useCurrentValue from '../../hooks/useCurrentValue'
import {
  useLazyImportCollectionQuery,
  useLazySearchGamesQuery,
  useImportGameMutation
} from '../../services/event'
import useHandleFormApiErrors from '../../hooks/useHandleFormApiErrors'
import { GameSetUpProps } from './types'
import CollectionsTableWithSearch from '../Collections/CollectionsTableWithSearch';
import { useAlert } from '../../contexts/AlertContext';

interface GameOption {
  id: string;
  name: string;
}

function GameSetUpCollectionPage({
  setCurrentStep,
}: GameSetUpProps) {
  const ref = useRef<HTMLInputElement>(null);
  const handleFormApiErrors = useHandleFormApiErrors()
  const [importCollection, { isFetching }] = useLazyImportCollectionQuery({ refetchOnFocus: true })
  const [searchGames, { data: games }] = useLazySearchGamesQuery()
  const [importGame] = useImportGameMutation()
  const { currentGameSetupValue, setCurrentGameSetupValue } = useCurrentValue()
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGame, setSelectedGame] = useState<GameOption | null>(null);
  const { setAlert } = useAlert()

  const [debouncedSearchTerm] = useDebounce(searchTerm, 200);

  const { collectionGames } = currentGameSetupValue

  useEffect(() => {
    const fetchGames = async () => {
      if (!debouncedSearchTerm) return
      const response = await searchGames(debouncedSearchTerm)
      if (response.error) {
        handleFormApiErrors({
          error: {},
          defaultMessage: "Something went wrong, please try again.",
          showFieldErrorsAsToast: true,
        })
      }
    }
    fetchGames()
  }, [debouncedSearchTerm])

  const handleUpload = async () => {
    if (!currentGameSetupValue.bggProfile) return
    const response = await importCollection(currentGameSetupValue.bggProfile)
    if (response.error) {
      handleFormApiErrors({
        error: {},
        defaultMessage: response.error.data?.error ?? "Something went wrong, please try again.",
        showFieldErrorsAsToast: true,
      })
    } else if (response.data.data.length > 0) {
      setCurrentGameSetupValue({ collectionGames: response.data.data })
    } else {
      handleFormApiErrors({
        error: {},
        defaultMessage: "No collection found against this username.",
        showFieldErrorsAsToast: true,
      })
    }
  }

  const handleClearAutocompleteInput = () => {
    const clearInputButton = ref.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0] as HTMLElement;
    if (clearInputButton) clearInputButton.click();
  };

  const handleGetGame = async (newValue: GameOption) => {
    if (!newValue || !newValue.id) return
    const response = await importGame(newValue.id)
    if ('error' in response) {
      handleFormApiErrors({
        error: {},
        defaultMessage: "Something went wrong, please try again.",
        showFieldErrorsAsToast: true,
      })
    } else {
      const existingGame = currentGameSetupValue.collectionGames.find(game => game._id === response.data.gameDetails._id)
      if (existingGame) {
        setAlert({
          type: 'info',
          description: 'Game already exists in the collection.'
        })
      } else {
        setCurrentGameSetupValue({ collectionGames: [response.data.gameDetails, ...currentGameSetupValue.collectionGames] })
        handleClearAutocompleteInput();
        setAlert({
          type: 'success',
          description: 'Game added successfully.'
        })
      }
    }
  }

  return (
    <div className='w-full mx-auto flex flex-col gap-4 items-center'>
      <Description text='Add your collection to the game night' />
      <SubTitle text='Import from other profiles to your collection' />
      <UploadField
        label='Upload from boardgamegeek.com'
        buttonLabel="Upload from account"
        placeholder='Enter your BGG-Username here...'
        value={currentGameSetupValue?.bggProfile || ''}
        disabled={isFetching}
        handleChange={(e: ChangeEvent<HTMLInputElement>) => setCurrentGameSetupValue({ bggProfile: e.target.value })}
        handleUpload={handleUpload}
      />
      <CollectionsTableWithSearch
        subTitle='Add games manually to your collection'
        data={collectionGames}
        isEdit={false}
        ref={ref}
        isFetching={isFetching}
        selectedGame={selectedGame}
        renderDeleteButton
        options={games?.data ? games.data.map((game: GameOption) => game) : []}
        handleGetGame={handleGetGame}
        setSelectedGame={setSelectedGame}
        setSearchTerm={setSearchTerm}
      />
      <div className='flex w-full justify-between'>
        <Button
          text='Previous'
          type='button'
          btnFor='prev'
          disabled={isFetching}
          onClick={() => {
            setCurrentStep('about')
          }}
        />
        <Button
          text='Next'
          type='button'
          disabled={isFetching}
          onClick={() => {
            setCurrentStep('games')
          }}
        />
      </div>
    </div>
  )
}

export default GameSetUpCollectionPage
