import { useCallback, useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFormik } from "formik";
import { useDropzone } from 'react-dropzone';
import { useOutletContext } from "react-router-dom";

import SubTitle from "../../../components/SubTitle"
import Button from "../../../components/Button";
import InputField from "../../../components/InputField";
import { useDeleteUserMutation, useUpdateUserMutation } from "../../../services/user";
import useHandleFormApiErrors from "../../../hooks/useHandleFormApiErrors";
import { useAlert } from "../../../contexts/AlertContext";
import CustomTabPanel from "../CustomTabPanel";
import { Checkbox, FormControlLabel, FormGroup, FormHelperText, MenuItem, TextField } from "@mui/material";
import { countries } from "./countries";
import { useLazyGetSignedUploadUrlQuery } from "../../../services/file";
import { AUTH0_CONNECTIONS, DOCUMENT_ACCEPT_TYPES, MAX_UPLOAD_SIZE } from "../../../config/constants";
import { useAuth } from "../../../contexts/AuthContext";
import useSession from "../../../hooks/useSession";
import CustomAlert from "../../../components/CustomAlert";

function SettingsPage() {
  const [user, selectedPanelIndex] = useOutletContext();
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const { setAlert } = useAlert()
  const { signOut } = useAuth()
  const session = useSession()

  const handleFormApiErrors = useHandleFormApiErrors()
  const [updateUser] = useUpdateUserMutation({})
  const [deleteUser] = useDeleteUserMutation({})
  const [getSignedUploadUrl] = useLazyGetSignedUploadUrlQuery({})


  const handleSubmit = useCallback(async (values, { setFieldValue }) => {
    if (typeof values.avatar === 'string' && user?.avatarKey) {
      values["avatar"] = user.avatarKey
    }
    if (typeof values.avatar === 'object') {
      const fileExtension = values.avatar.name.split(".").pop();
      const uploadParamsResponse = await getSignedUploadUrl({ fileExtension })

      const formData = new FormData();
      formData.append("file", values.avatar);

      const fileUploadResponse = await fetch(uploadParamsResponse.data.signedUrl, {
        method: 'PUT',
        body: values.avatar,
      });
      if (!fileUploadResponse.ok) {
        handleFormApiErrors({
          defaultMessage: "Something went wrong while uploading profile image, please try again.",
          error: {},
          showFieldErrorsAsToast: true,
        })
        return
      }
      values["avatar"] = uploadParamsResponse.data.fileKey
    }
    const response = await updateUser(values)

    if ('error' in response) {
      handleFormApiErrors({
        defaultMessage: response.error.data.error || 'Something went wrong. Please try again.',
        error: {},
        showFieldErrorsAsToast: true,
      })
    } else {
      setAlert({
        type: 'success',
        description: 'Profile updated successfully.',
      })
      setFieldValue("currentPassword", "")
      setFieldValue("newPassword", "")
    }
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      avatar: user?.avatar ?? '',
      fullName: user?.fullName ?? '',
      publicName: user?.publicName ?? '',
      country: user?.country ?? '',
      city: user?.city ?? '',
      email: session?.user?.email ?? '',
      gameNightNotification: !!user?.gameNightNotification,
      friendsActivities: !!user?.friendsActivities,
      updatesOnBGA: !!user?.updatesOnBGA,
      updatesOnBGA2: !!user?.updatesOnBGA2,
      userPreferences: { showDeleteMessage :user?.userPreferences.showDeleteMessage},
      currentPassword: '',
      newPassword: '',
    },
    onSubmit: handleSubmit,
  })

  const {
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    multiple: false,
    noKeyboard: true,
    accept: DOCUMENT_ACCEPT_TYPES,
    useFsAccessApi: false,
    maxSize: MAX_UPLOAD_SIZE,
    onDrop: async acceptedFiles => {
      if (acceptedFiles.length) {
        formik.setFieldValue("avatar", acceptedFiles[0])
      }
    }
  });

  useEffect(() => {
    if (fileRejections?.length > 0) {
      handleFormApiErrors({
        defaultMessage: fileRejections[0]?.errors[0]?.message || 'Something went wrong. Please contact support.',
        error: {},
        showFieldErrorsAsToast: true,
      })
    }
  }, [fileRejections])

  const handleDelete = async () => {
    await deleteUser({})
    signOut()
    setShowDeletePopup(false)
  }

  const isLoggedInWithUsername = session?.user?.connection === AUTH0_CONNECTIONS.USERNAME_PASSWORD_AUTHENTICATION

  return (
    <CustomTabPanel selectedPanelIndex={selectedPanelIndex} index={1}>
      <form onSubmit={formik.handleSubmit} className="max-w-[40rem]">
        {<CustomAlert
              open={showDeletePopup}
              title={"Delete Account"}
              text={`WARNING: Deleting your account will result in the permanent loss of access to all Board Game Arc services.
                Your personal data will be irreversibly deleted.
                This action is final and cannot be undone after 14 days.
                Proceed with caution.`}
              onClose={() => {setShowDeletePopup(false)}}
              onAgree={handleDelete}
              titleAgree={"Delete"}
              titleCancel={"Cancel"}
            />}
        <div className="flex flex-col space-y-4">
          <Accordion
            sx={{
              bgcolor: '#282E3E',
              color: '#FFFFFF',
              borderRadius: '15px'
            }}
            square='false'
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ fontWeight: 'bold', fontSize: '1.2rem', py: .5 }}
            >
              About You
            </AccordionSummary>
            <AccordionDetails sx={{ color: '#CCCCCC' }}>
              This is public information about you that anyone in the website can view in your profile.
              <div className="mt-4">
                {/* <div className='relative'>
                  <div {...getRootProps({})}>
                    <div className='w-full pt-0 pb-2'>
                      <p className='font-semibold font-poppins text-[16px] text-white3 text-left mb-2'>
                        Profile Image
                      </p>
                      <div className='relative flex'>
                        <div className='flex-1 bg-input text-white3 p-3 rounded-l-lg border border-border outline-none'>
                          <input {...getInputProps()} />
                          <p>{formik.values.avatar?.name ? formik.values.avatar.name : "Select a file here..."}</p>
                        </div>
                        <Button
                          type="button"
                          text="Upload Picture"
                          className='hidden md:flex bg-button uppercase font-semibold font-poppins text-[18px] text-white1 text-center items-center sm:py-2 px-6 my-0 rounded-r-[100px] cursor-pointer'
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className='relative'>
                  <InputField
                    label='Full Name'
                    placeholder='Your Name'
                    value={formik.values.fullName}
                    name='fullName'
                    onValueChange={formik.handleChange}
                  />
                  {formik.touched.fullName && formik.errors.fullName && (
                    <div className='absolute bottom-0 text-error text-base'>
                      {formik.errors.fullName}
                    </div>
                  )}
                </div>
                <div className='relative'>
                  <InputField
                    label='Public Name'
                    placeholder='Your public name'
                    value={formik.values.publicName}
                    name='publicName'
                    onValueChange={formik.handleChange}
                  />
                  {formik.touched.publicName && formik.errors.publicName && (
                    <div className='absolute bottom-0 text-error text-base'>
                      {formik.errors.publicName}
                    </div>
                  )}
                </div>
                <div className='relative pt-0 pb-6'>
                  <p className='font-semibold font-poppins text-[16px] text-white3 text-left mb-2'>
                    Country
                  </p>
                  <TextField
                    className="w-full"
                    variant="outlined"
                    name="country"
                    id="country"
                    placeholder="Select Country"
                    select
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.country &&
                      Boolean(formik.errors.country)
                    }
                    helperText={
                      formik.touched.country && formik.errors.country
                    }
                  >
                    <MenuItem disabled value="">
                      Select Country
                    </MenuItem>
                    {countries.map((country) => (
                      <MenuItem key={country.value} value={country.value}>
                        {country.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className='relative'>
                  <InputField
                    label='City '
                    placeholder='Enter your city'
                    value={formik.values.city}
                    name='city'
                    onValueChange={formik.handleChange}
                  />
                  {formik.touched.city && formik.errors.city && (
                    <div className='absolute bottom-0 text-error text-base'>
                      {formik.errors.city}
                    </div>
                  )}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              bgcolor: '#282E3E',
              color: '#FFFFFF',
              borderRadius: '15px'
            }}
            square='false'
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              sx={{ fontWeight: 'bold', fontSize: '1.2rem', py: .5 }}
            >
              Contact
            </AccordionSummary>
            <AccordionDetails>
              This is private information about you that only you and the admins can view.
              <div className='relative mt-4'>
                <InputField
                  label='Email'
                  placeholder='example@example.com'
                  value={formik.values.email}
                  name='email'
                  readOnly
                  onValueChange={formik.handleChange}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              bgcolor: '#282E3E',
              color: '#FFFFFF',
              borderRadius: '15px'
            }}
            square='false'
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              sx={{
                fontWeight: 'bold', fontSize: '1.2rem', py: .5
              }}
            >
              Account Preferences
            </AccordionSummary>
            <AccordionDetails>
              <SubTitle text="Newsletters & Board Game Content" />
              <FormGroup>
                <FormControlLabel
                  name="gameNightNotification"
                  onChange={() =>
                    formik.setFieldValue(
                      'gameNightNotification',
                      !formik.values.gameNightNotification,
                    )
                  }
                  checked={true}
                  control={<Checkbox checked={formik.values.gameNightNotification} />}
                  label="Game Night Notifications"
                />
                <FormControlLabel
                  name="friendsActivities"
                  onChange={() =>
                    formik.setFieldValue(
                      'friendsActivities',
                      !formik.values.friendsActivities,
                    )
                  }
                  checked={formik.values.friendsActivities}
                  control={<Checkbox />}
                  label="Friends Activities"
                />
                <FormControlLabel
                  name="updatesOnBGA"
                  onChange={() =>
                    formik.setFieldValue(
                      'updatesOnBGA',
                      !formik.values.updatesOnBGA,
                    )
                  }
                  control={<Checkbox checked={formik.values.updatesOnBGA} />}
                  label="Updates on Board Game Arc"
                />
                {/* <FormControlLabel
                  name="updatesOnBGA2"
                  onChange={() =>
                    formik.setFieldValue(
                      'updatesOnBGA2',
                      !formik.values.updatesOnBGA2,
                    )
                  }
                  control={<Checkbox checked={formik.values.updatesOnBGA2} />}
                  label="Updates on Board Game Arc"
                /> */}
                 <FormControlLabel
                  name="showDeleteMessage"
                  onChange={() =>
                    formik.setFieldValue(
                      'userPreferences.showDeleteMessage',
                      !formik.values.userPreferences.showDeleteMessage,
                    )
                  }
                  control={<Checkbox checked={formik.values.userPreferences.showDeleteMessage} />}
                  label="Show Delete Confirmation"
                />
                <FormHelperText sx={{ color: '#FFFFFF', ml: 4 }}>Enable this option to see a confirmation dialog when deleting a game or game night</FormHelperText>
              </FormGroup>

              <SubTitle text="Delete your account" className="mt-3" />
              When you delete your account, you lose access to all Board Game Arc account services, and we permanently delete your personal data. 
              This action is final and cannot be undone.
              <div className="mt-2 flex flex-col sm:flex-row">
                <Button text="Learn More" type="button" />
                <Button text="Delete My Account" type="button" className="bg-transparent text-warning" onClick={() => { setShowDeletePopup(true)}}/>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              bgcolor: '#282E3E',
              color: '#FFFFFF',
              borderRadius: '15px'
            }}
            square='false'
          >
            {
              isLoggedInWithUsername ? (
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                  sx={{ fontWeight: 'bold', fontSize: '1.2rem', py: .5 }}
                >
                  Security
                </AccordionSummary>
              ) : <></>
            }
            {
              isLoggedInWithUsername ? (
                <AccordionDetails>
                  <SubTitle text="Change Password" />
                  When you change your password, we keep you logged in to this device but may log you out from other devices.
                  <div className='relative'>
                    <InputField
                      label='Current Password'
                      placeholder='************'
                      password
                      value={formik.values.currentPassword}
                      name='currentPassword'
                      onValueChange={formik.handleChange}
                    />
                    {formik.touched.currentPassword && formik.errors.currentPassword && (
                      <div className=' absolute bottom-0 text-error text-base'>
                        {formik.errors.currentPassword}
                      </div>
                    )}
                  </div>
                  <div className='relative'>
                    <InputField
                      label='New Password'
                      placeholder='************'
                      password
                      value={formik.values.newPassword}
                      name='newPassword'
                      onValueChange={formik.handleChange}
                    />
                    {formik.touched.newPassword && formik.errors.newPassword && (
                      <div className=' absolute bottom-0 text-error text-base'>
                        {formik.errors.newPassword}
                      </div>
                    )}
                  </div>
                </AccordionDetails>
              ) : <></>
            }
          </Accordion>
          <div className='flex w-full justify-end'>
            <Button text='Save Changes' type='submit' disabled={formik.isSubmitting} />
          </div>
        </div>
      </form>
    </CustomTabPanel>
  )
}

export default SettingsPage
