import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useDebounce } from "use-debounce";

import PageTitle from '../../components/PageTitle'
import PageLoader from '../../components/PageLoader/PageLoader'
import Stat from '../../components/Stat/Stat'
import { Game } from '../../components/GamesTable/types'
import UploadField from '../../components/UploadField'
import useCurrentValue from '../../hooks/useCurrentValue'
import {
  useLazyImportCollectionQuery,
  useLazySearchGamesQuery,
  useImportGameMutation,
} from '../../services/event'
import useHandleFormApiErrors from '../../hooks/useHandleFormApiErrors'
import CollectionsTableWithSearch from '../Collections/CollectionsTableWithSearch';
import { useAlert } from '../../contexts/AlertContext';
import { useGetCollectionsQuery } from '../../services/collection';

interface GameOption {
  id: string;
  name: string;
}

function CollectionsPage() {
  const { setAlert } = useAlert()
  const handleFormApiErrors = useHandleFormApiErrors()
  const { data, isLoading, currentData } = useGetCollectionsQuery({}, { refetchOnMountOrArgChange: true })
  const [importCollection, { isFetching, }] = useLazyImportCollectionQuery({ refetchOnFocus: true })
  const [searchGames, { data: games }] = useLazySearchGamesQuery({})
  const [importGame] = useImportGameMutation()

  useEffect(() => {
    if (data) {
      setCurrentGameSetupValue({ collectionGames: data?.collectionGames ?? [] })
    }
  }, [data])

  const ref = useRef<HTMLInputElement>(null);
  const { currentGameSetupValue, setCurrentGameSetupValue } = useCurrentValue()
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGame, setSelectedGame] = useState<Game | null>(null);

  const [debouncedSearchTerm] = useDebounce(searchTerm, 200);

  const { collectionGames } = currentGameSetupValue

  useEffect(() => {
    const fetchGames = async () => {
      if (!debouncedSearchTerm) return
      const response = await searchGames(debouncedSearchTerm)
      if (response.error) {
        handleFormApiErrors({
          error: {},
          defaultMessage: "Something went wrong, please try again.",
          showFieldErrorsAsToast: true,
        })
      }
    }
    fetchGames()
  }, [debouncedSearchTerm])


  const handleSelect = (selectedRows: string[]) => {
    setCurrentGameSetupValue({
      nightGames: selectedRows.map((id: string) => collectionGames.find((item) => item._id === id)).filter(Boolean)
    })
  }

  const handleUpload = async () => {
    if (!currentGameSetupValue.bggProfile) return
    const response = await importCollection(currentGameSetupValue.bggProfile)
    if ('error' in response) {
      handleFormApiErrors({
        error: {},
        defaultMessage: response.error.data?.error ?? "Something went wrong, please try again.",
        showFieldErrorsAsToast: true,
      })
    } else if (response.data.data.length > 0) {
      setCurrentGameSetupValue({ collectionGames: response.data.data })
    } else {
      handleFormApiErrors({
        error: {},
        defaultMessage: "No collection found against this username.",
        showFieldErrorsAsToast: true,
      })
    }
  }

  const handleClearAutocompleteInput = () => {
    const clearInputButton = ref.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0] as HTMLElement;
    if (clearInputButton) clearInputButton.click();
  };

  const setCurrentValue =  () => {
    setCurrentGameSetupValue({
      nightGames:[]
    });  
};

  const handleGetGame = async (newValue: GameOption) => {
    if (!newValue || !newValue.id) return
    const response = await importGame(newValue.id)
    if ('error' in response) {
      handleFormApiErrors({
        error: {},
        defaultMessage: "Something went wrong, please try again.",
        showFieldErrorsAsToast: true,
      })
    } else {
      const existingGame = currentGameSetupValue.collectionGames.find(game => game._id === response.data.gameDetails._id)
      if (existingGame) {
        setAlert({
          type: 'info',
          description: 'Game already exists in the collection.'
        })
      } else {
        setCurrentGameSetupValue({ collectionGames: [response.data.gameDetails, ...currentGameSetupValue.collectionGames] })
        handleClearAutocompleteInput()
        setAlert({
          type: 'success',
          description: 'Game added successfully.'
        })
      }
    }
  }

  if (isLoading && !currentData) return <PageLoader />

  return (
    <div className='max-w-[1400px] w-full mx-auto flex flex-col gap-4 px-0 md:px-8 pt-4'>
      <PageTitle title='Collection' />
      <div className='flex flex-col sm:flex-row w-full items-start justify-center col-span-3 gap-8'>
        <Stat name="In Collection" value={data?.collectionGames?.length} />
        <Stat name="Wishlist" value={0} />
        <Stat name="Played" value={0} />
        <Stat name="Previously Owned" value={0} />
      </div>

      <div className='w-full py-6'>
        <CollectionsTableWithSearch
          subTitle="Add games to the game night"
          data={collectionGames}
          isEdit={true}
          ref={ref}
          isFetching={isFetching}
          selectedGame={selectedGame}
          renderDeleteButton={true}
          gameTitleWidth="w-96"
          options={games?.data ? games.data.map((game: GameOption) => game) : []}
          handleGetGame={handleGetGame}
          setSelectedGame={setSelectedGame}
          setSearchTerm={setSearchTerm}
          handleSelect={handleSelect}
          selectedRows={currentGameSetupValue}
          remove={true}
          setCurrentValue={setCurrentValue}
        />
        <div className='mt-8 flex flex-col md:flex-row md:space-x-8'>
          <UploadField
            label='Upload from boardgamegeek.com account'
            placeholder='Enter your BGG-Username here...'
            buttonLabel="Upload from account"
            value={currentGameSetupValue?.bggProfile || ''}
            disabled={isFetching}
            handleChange={(e: ChangeEvent<HTMLInputElement>) => setCurrentGameSetupValue({ bggProfile: e.target.value })}
            handleUpload={handleUpload}
          />
          {/* <UploadField
            label='Upload from boardgamegeek.com file'
            buttonLabel="Upload from file"
            placeholder='Select a BGG file here...'
            disabled
          /> */}
        </div>
      </div>
    </div>
  )
}

export default CollectionsPage;
