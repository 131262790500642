
import React, { useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import ProfileImg from '../../assets/images/profile.png';
import { Button, CircularProgress } from '@mui/material';
import { useLazyDeleteStoredFileQuery, useLazyGetSignedUploadUrlQuery } from '../../services/file';
import { Delete, Edit, FileUpload } from '@mui/icons-material';
import { useUpdateUserMutation } from '../../services/user';
import { useAlert } from '../../contexts/AlertContext';


interface UploadProfilePictureProps {
  handleClose: () => void;
  data: any;
}

const UploadProfilePicture: React.FC<UploadProfilePictureProps> = ({ handleClose, data }) => {
  const { user: { avatar,avatarKey } } = data;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
  const [userAvatar, setUserAvatar] = useState<string | null>((avatar));
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setAlert } = useAlert()

  
  const [getSignedUploadUrl] = useLazyGetSignedUploadUrlQuery({});
  const [deleteStoredFile] = useLazyDeleteStoredFileQuery({});
  const [updateUser] = useUpdateUserMutation({});

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCropImage = async (croppedImage:File) => {
    try {
      const updatedUser = { ...data.user };
      setLoading(true)
      if (croppedImage && typeof croppedImage === 'object') {        
        const fileExtension = croppedImage.name.split('.').pop();
        const uploadParamsResponse = await getSignedUploadUrl({ fileExtension });
        const fileUploadResponse = await fetch(uploadParamsResponse.data.signedUrl, {
          method: 'PUT',
          body: croppedImage,
        });
        if (!fileUploadResponse.ok) {
          throw new Error('Error cropping image');
        }
        updatedUser.avatar = uploadParamsResponse.data.fileKey;
      }
      
      const { data: { success } } = await updateUser(updatedUser);
      if (success) {
        setLoading(false)
        handleClose();
        setAlert({
          type: 'success',
          description: 'Profile Picture Updated Sucessfully',
        })
      }
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  };

  const showCroppedImage = async () => {
    try {
      if(userAvatar && croppedAreaPixels){
        const croppedImage = await getCroppedImg(userAvatar, croppedAreaPixels) as File
        await handleCropImage(croppedImage)
      }
    } catch (e) {
      console.error(e);
    }
  };


 const createImage = (url:string) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
  })

 async function getCroppedImg(
  imageSrc:string,
  pixelCrop:Area,
  flip  = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc) as HTMLImageElement
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  // set canvas size to match the bounding box
  canvas.width = image.width
  canvas.height = image.height

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(image.width / 2, image.height / 2)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  // draw rotated image
  ctx.drawImage(image, 0, 0)

  const croppedCanvas = document.createElement('canvas')

  const croppedCtx = croppedCanvas.getContext('2d')

  if (!croppedCtx) {
    return null
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width
  croppedCanvas.height = pixelCrop.height

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  )

  return new Promise<File>((resolve, reject) => {
    croppedCanvas.toBlob(blob => {
      if (!blob) {
        reject(new Error('Failed to crop image'));
        return;
      }
      // Convert the Blob to a File
      const file = new File([blob], 'croppedImage.jpg', { type: 'image/jpeg', lastModified: Date.now() });
      resolve(file);
    }, 'image/jpeg');
  });
}

const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files?.[0];
  if (file) {
    if(file?.size > 5 * 1024 * 1024 ){
      setAlert({
        type: 'warning',
        description: 'Maximum Image Size exceeded (5 MB limit). Please select a smaller file.',
      })
      return
    }
    const imageUrl = URL.createObjectURL(file);
    setUserAvatar(imageUrl);
  }
};

const handleDelete = async () => {
  if (avatarKey !== "") {
    const fileKey = avatarKey;
    setLoading(true);
    const response = await deleteStoredFile({ fileKey });
    if (response.isSuccess) {
      const updatedUser = { ...data.user };
      updatedUser.avatar = "";
      updatedUser.avatarKey = "";
      const {
        data: { success },
      } = await updateUser(updatedUser);
      if (success) {
        setLoading(false);
        // handleClose();
        setAlert({
          type: "success",
          description: "Profile Picture Deleted",
        });
      }
    }
  }
  setUserAvatar("");
  setIsHovered(false);
  setLoading(false);
  setZoom(1);
};


 return (
  
  <div className="flex flex-col w-96 h-96 bg-input justify-center items-center rounded-lg p-4" >
    <h1 className='text-white3 font-normal text-base font-poppins absolute top-5 text-xl'>{'Set Profile Picture'}</h1>
      <div>

        {(userAvatar && userAvatar !== "") ? (
          <div className="flex flex-col w-['1rem'] h-['1rem'] justify-center items-center" >
            {loading && <CircularProgress size={70} sx={{zIndex:10, position: 'absolute', top: '36%', left: '41%', color:'lightslategray'}}/>}
            <Delete onClick={() => {handleDelete()}} sx={{ color: 'red', position: 'absolute', right: 70, top: 51, zIndex: 10, fontSize: '2rem' }} />
            <Cropper
              image={userAvatar ? userAvatar : ''}
              crop={crop}
              zoom={zoom}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              aspect={1}
              style={{
                containerStyle: {
                  height: '60%',
                  width: '60%',
                  marginLeft: '20%',
                  marginTop: '15%',
                  border: '2px solid black',
                },
              }}
            />
          </div>
        ) : (
          <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} className="relative w-[14rem] h-[14rem] mt-[2rem]">
            {isHovered && (
              <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-40 rounded-full"
                onClick={() => document.getElementById('fileInput')?.click()}
              >
                {(userAvatar)
                  ? <div><Edit style={{ fontSize: '3rem', color: 'black' }} /></div>
                  : <FileUpload style={{ fontSize: '3rem', color: 'black' }} />}
              </div>
            )}
            <input id="fileInput" type="file" accept="image/*" style={{ display: 'none' }} onChange={handleFileUpload} />
            <img src={ProfileImg} crossOrigin={'anonymous'} alt="Profile" />
          </div>
        )}
      </div>

      <div className={`flex flex-row  w-80 justify-center items-center gap-8`}
        style={{marginTop: userAvatar ? '80%' : '10%'}}
      >
        <Button onClick={handleClose} variant="outlined" sx={{ color: 'white', width: '40%' }}>
          Cancel
        </Button>
        {userAvatar && <Button variant="contained" sx={{ backgroundColor: '#006DCC', width: '40%' }} onClick={showCroppedImage}>
          Save
        </Button>}
      </div>
    </div>
  );
};

export default UploadProfilePicture;
