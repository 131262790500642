import React from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom'

import LoginPage from '../../pages/auth/Login/Login'
import SignUpPage from '../../pages/auth/SignUp/SignUp'
import ForgotPassword from '../../pages/auth/ForgotPassword/ForgotPassword'
import GameSetup from '../../pages/GameSetUp/GameSetUp'

import RequireSignIn from './RequireSignIn'
import AuthCallback from './AuthCallback'
import GameNightPage from '../../pages/GameNight/GameNight'
import ControlRoomPage from '../../pages/ControlRoom/ControlRoom';
import Error404 from '../../pages/error/Error404';
import CollectionsPage from '../../pages/Collections/Collections';
import Activities from '../../pages/profile/Activities';
import SettingsPage from '../../pages/profile/Settings/Settings';
import ProfilePage from '../../pages/profile';
import Error403 from '../../pages/error/Error403';

export { default as history } from './history'

function Router(): React.ReactElement {
  return (
    <Routes>
      <Route path='/auth-callback' element={<AuthCallback />} />
      <Route path='/signin' element={<LoginPage />} />
      <Route path='signup' element={<SignUpPage />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route
        path='/'
        element={
          <RequireSignIn>
            <GameSetup />
          </RequireSignIn>
        }
      />
      <Route path='/game-nights/:eventId' element={<GameNightPage />} />
      <Route path='/game-nights/:eventId/edit' element={<GameSetup editMode />} />
      <Route path='control-room' element={<ControlRoomPage />} />
      <Route path='collections' element={<CollectionsPage />} />
      <Route path="/profile" element={<ProfilePage />}>
        <Route path='activities' element={<Activities />} />
        <Route path='settings' element={<SettingsPage />} />
      </Route>
      <Route path='/unauthorized' element={<Error403 />} />
      <Route path='*' element={<Error404 />} />
    </Routes>
  )
}

export default Router
