import { SyntheticEvent, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Outlet, useLocation } from "react-router-dom";

import PageTitle from "../../components/PageTitle"
import Stat from "../../components/Stat"
import SubTitle from "../../components/SubTitle"
import ProfileTabs from "./Tabs";
import { useGetUserProfileQuery } from "../../services/user";
import PageLoader from "../../components/PageLoader/PageLoader";
import useSession from "../../hooks/useSession";
import ProfileImg from '../../assets/images/profile.png'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import EditIcon from '@mui/icons-material/Edit';
import Model from "reactjs-popup";
import UploadProfilePicture from "./UploadProfilePicture";

function ProfilePage() {
    const { user } = useSession()
    const { data, isLoading } = useGetUserProfileQuery({}, { refetchOnMountOrArgChange: true })
    const [selectedPanelIndex, setSelectedPanel] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const location = useLocation()

    useEffect(() => {
        setSelectedPanel(
            location.pathname.includes("/profile/settings")
                ? 1
                : 0
        )
    }, [location.pathname])

    const handleChange = (_: SyntheticEvent, newValue: number) => {
        setSelectedPanel(newValue);
    };

    if (isLoading) return <PageLoader />

    const handleClose = () => {
      setOpenModal(false)
    };

    return (
        <div className='max-w-[1400px] w-full mx-auto flex flex-col gap-4 px-0 md:px-8 pt-4'>
          <div>
            <div>
              {openModal && (
                <Model modal={true} open={openModal} onClose={handleClose} >
                  { <UploadProfilePicture handleClose={handleClose} data={data} />}
                </Model>
              )}
            </div>
          </div>
            <PageTitle title='Profile' className="" />
            <div className="flex flex-col lg:flex-row items-center lg:space-x-10 mt-4 ">
              <div>
                <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}  className="relative w-40 h-40">
                  {isHovered && (
                    <div onClick={()=>{setOpenModal(true)}} className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-40 rounded-full">
                      {(data?.user?.avatar) 
                      ? <EditIcon style={{fontSize:'3rem', color:'black'}}/>
                      : <FileUploadIcon style={{fontSize:'3rem', color:'black'}} />}
                    </div>
                    )}
                    <img
                      alt={data?.user?.fullName ? data?.user.fullName : 'Profile image'}
                      className="w-40 h-40 rounded-full max-w-none"
                      src={data?.user?.avatar || user?.picture || ProfileImg}
                    />
                </div>
                    <SubTitle text={data?.user?.fullName ? data?.user.fullName : ''} className="mt-4 text-center" />
              </div>
                <div className="w-full gap-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                    <Stat name="In Collection" value={data?.user?.collectionsCount} />
                    <Stat name="Wishlist" value={0} />
                    <Stat name="Played" value={0} />
                    <Stat name="Previously Owned" value={0} />
                    <Stat name="Friends" value={0} />
                    <Stat name="Games Nights Organized" value={0} />
                    <Stat name="Games Nights Open" value={0} />
                    <Stat name="Reviewed Games" value={0} />
                </div>
            </div>
            <Box sx={{ width: '100%' }}>
                <ProfileTabs selectedPanelIndex={selectedPanelIndex} handleChange={handleChange} />
                <Outlet context={[data?.user, selectedPanelIndex]} />
            </Box>
        </div>
    )
}

export default ProfilePage
